/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPE 0) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_type_0 {
	display:block;
	line-height:1;
	font-family:'gilroy-extrabold', sans-serif;
	@include fluid-type(360px, 1600px, 32px, 192px);

	&.long {
		margin:0 auto; padding-top:12px;
		width:100%; max-width:$site_width_base;
		@include fluid-type(360px, 1600px, 32px, 96px);
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPE 1) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_type_1 {
	display:block;
	margin-bottom:-.5em;
	font-size:18px;
	font-family:'gilroy-extrabold', sans-serif;
	text-transform:uppercase;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPO 1) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_typo_1 {
	@extend h1;
	font-family:'gilroy-medium', sans-serif;

	@media screen and (max-width:768px)
	{
		font-size:24px;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPO 2) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_typo_2 {
	@extend h2;
	font-family:'gilroy-semibold', sans-serif;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPO 3) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_typo_3 {
	@extend h3;
	font-family:'gilroy-medium', sans-serif;
}
