/* ------------------------------------------------------------------------------------------------------------------------ */
/* SHARE NAVIGATION ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.share_navigation {
	display:flex;
	flex-direction:column;
	flex-wrap:nowrap;
	justify-content:flex-end;
	flex:1;
	padding-top:96px;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding-top:24px;
	}
	@media screen and (max-width:768px)
	{
		display:none;
		padding:32px 24px;
	}

	.nav_heading {
		color:rgba($color_theme_01-03, .48);
		font-size:$font_size_small;
		font-family:'gilroy-medium', sans-serif;
		text-transform:uppercase;
	}

	ul {
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		padding-top:12px;
		line-height:1.4;
		list-style:none;

		& > li {
			padding-right:12px;
			width:calc(33.33% - 12px);

			& > a {
				@extend .animate;
				@extend .relative;
				color:rgba($color_theme_01-03, .48);

				&:after {
					@extend .animate;
					@extend .absolute;
					content:"";
					left:0; right:0; bottom:0;
					height:1px;
					background:rgba($color_theme_01-03, .48);
				}

				@media screen and (min-width:769px)
				{
					&:hover,
					&:focus {
						color:$color_theme_01-03;

						&:after {
							background:$color_theme_01-03;
						}
					}
				}
			}
		}
	}
}
