/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAZYLOAD --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.lazyload_wrapper {
	@extend .relative;

	picture,
	img {
		@extend .relative;
		@extend .full_width;
		opacity:0;

		&.lazyloaded {
			opacity:1;
		}
	}
}
