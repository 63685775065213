@charset "UTF-8";
/*
 * Bloomfield Installations WordPress Theme by Andrea Codato @ GRIT Digital
 * https://gritdigital.co.uk/
 * Copyright © GRIT Digital
 */
/* BASE */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- PRIMARY --- */
/* --- SYSTEM COLORS --- */
/* --- SOCIAL COLORS --- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@font-face {
  font-family: 'gilroy-extrabold';
  src: url("../../assets/fonts/gilroy/gilroy-extrabold.woff2") format("woff2"), url("../../assets/fonts/gilroy/gilroy-extrabold.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gilroy-bold';
  src: url("../../assets/fonts/gilroy/gilroy-bold.woff2") format("woff2"), url("../../assets/fonts/gilroy/gilroy-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gilroy-semibold';
  src: url("../../assets/fonts/gilroy/gilroy-semibold.woff2") format("woff2"), url("../../assets/fonts/gilroy/gilroy-semibold.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gilroy-medium';
  src: url("../../assets/fonts/gilroy/gilroy-medium.woff2") format("woff2"), url("../../assets/fonts/gilroy/gilroy-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gilroy-regular';
  src: url("../../assets/fonts/gilroy/gilroy-regular.woff2") format("woff2"), url("../../assets/fonts/gilroy/gilroy-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAYOUT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* DOCUMENT --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
* {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  outline: none; }

html {
  text-size-adjust: 100%;
  background-color: #F2F2F2; }

body {
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #14151A;
  font-family: "gilroy-regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: default;
  overflow-x: auto;
  overflow-y: scroll; }
  @media screen and (max-width: 768px) {
    body {
      line-height: 1.4; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SECTIONS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECTIONS ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
::selection {
  color: #FFF;
  background-color: #EE5533; }

::-moz-selection {
  color: #FFF;
  background-color: #EE5533; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* KEYFRAMES -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@keyframes spin {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes highlight {
  0% {
    transform: translate(-50%, -50%) scale(1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1); }
  100% {
    transform: translate(-50%, -50%) scale(1); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CLEARFIX --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.clearfix, .form {
  *zoom: 1; }
  .clearfix:before, .form:before, .clearfix:after, .form:after {
    content: "";
    display: table;
    line-height: 0; }
  .clearfix:after, .form:after {
    clear: both; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ALIGNMENT -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.left {
  float: left; }

.right {
  float: right; }

.center {
  margin: 0 auto; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MARGINS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_margin {
  margin: 0 !important; }

.reset_margin_top {
  margin-top: 0 !important; }

.reset_margin_right {
  margin-right: 0 !important; }

.reset_margin_bottom {
  margin-bottom: 0 !important; }

.reset_margin_left {
  margin-left: 0 !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PADDINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_padding {
  padding: 0 !important; }

.reset_padding_top {
  padding-top: 0 !important; }

.reset_padding_right {
  padding-right: 0 !important; }

.reset_padding_bottom {
  padding-bottom: 0 !important; }

.reset_padding_left {
  padding-left: 0 !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_border {
  border: 0 solid !important; }

.reset_border_top {
  border-top: 0 solid !important; }

.reset_border_right {
  border-right: 0 solid !important; }

.reset_border_bottom {
  border-bottom: 0 solid !important; }

.reset_border_left {
  border-left: 0 solid !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* POSITIONS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.relative, .form .input_text, .form .field_select, .form .input_radio,
.form .input_checkbox, .lazyload_wrapper, .lazyload_wrapper picture,
.lazyload_wrapper img, .cookie_notification p > a, .form_type_1 .field, .form_type_1 .field_select select, .typography ul > li, .typography p a:not(.button), .main_navigation .main_navigation-inner > ul > li > a, .social_navigation > ul > li > a, .share_navigation ul > li > a, .hero, .hero .heading h1 strong, .hero .heading .heading_typo_1 strong, .hero .hero_in .heading .center_content h2 strong, .hero_in .hero .heading .center_content h2 strong, .hero .hero_in .heading .center_content .heading_typo_2 strong, .hero_in .hero .heading .center_content .heading_typo_2 strong, .hero .hero_in .heading .center_content .list_type_3 .item .title strong, .hero_in .hero .heading .center_content .list_type_3 .item .title strong, .hero .list_type_3 .item .hero_in .heading .center_content .title strong, .list_type_3 .item .hero_in .hero .heading .center_content .title strong, .hero_in, .hero_in:before, .list_type_1 .item, .list_type_1 .item:before, .list_type_4 .item .thumb, .list_type_4 .item .thumb:before, .list_type_4 .item .text, .article_wrapper, .article_wrapper .date a,
.article_wrapper .date span,
.article_wrapper .category a,
.article_wrapper .category span, .testimonials_wrapper, .careers_wrapper, .footer .link, .footer .email_link {
  position: relative;
  z-index: 1; }

.absolute, .cookie_notification p > a:after, .cookie_notification .cookie_close.icon, .typography ul > li:before, .typography p a:not(.button):after, .main_navigation .main_navigation-inner > ul > li > a:after, .share_navigation ul > li > a:after, .article_wrapper .date a:after,
.article_wrapper .category a:after, .header:before, .footer .link:after {
  position: absolute;
  z-index: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOURS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Dark gray --- */
.color_01-01,
a.color_01-01 {
  color: #14151A !important; }

@media screen and (min-width: 769px) {
  a.color_01-01:hover, a.color_01-01:focus {
    color: #14151A !important;
    cursor: pointer; } }

/* --- White --- */
.color_white,
a.color_white {
  color: #FFF !important; }

@media screen and (min-width: 769px) {
  a.color_white:hover, a.color_white:focus {
    color: #FFF !important;
    cursor: pointer; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BACKGROUNDS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL SIZE -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_size, .form label.in_field_label, .hero .cycle, .hero .hero_bg + picture, .hero_in .bg_wrapper, .hero_in .bg_wrapper .hero_in_bg + .lazyload_wrapper, .list_type_1 .item .lazyload_wrapper, .list_type_4 .item .thumb .lazyload_wrapper, .careers_wrapper .background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL WIDTH ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_width, .lazyload_wrapper picture,
.lazyload_wrapper img {
  display: block;
  width: 100%;
  height: auto; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANIMATE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.animate, a.color_01-01, a.color_white, .form label.in_field_label, .form .input_text, .form .field_select, .form .input_radio span,
.form .input_checkbox span, .form .input_radio span svg,
.form .input_checkbox span svg, .field_feedback, .badge_valid,
.badge_invalid, .cookie_notification p > a:after, .cookie_notification .cookie_close, .cookie_notification .cookie_close.icon svg, .alert_banner_con, .alert_banner_con .alert_banner_inner, .button_type_1 svg,
.button_type_2 svg, .button_type_3 span:first-child, .button_type_3 .icon, .button_type_4 svg, .scroll_down svg, .scroll_up svg, .prev_type_1,
.next_type_1, .prev_type_1 svg,
.next_type_1 svg, .close_type_1, .close_type_1 svg, .form_type_1 .field-wrapper_hidden, .form_type_1 .field label a, .form_type_1 input[type=tel],
.form_type_1 input[type=text],
.form_type_1 input[type=email],
.form_type_1 input[type=password],
.form_type_1 textarea, .form_type_1 .field_select select, .form_type_1 .field_select .arrow, .typography p a:not(.button), .typography p a:not(.button):after, .main_navigation .main_navigation-inner > ul > li > a, .main_navigation .main_navigation-inner > ul > li > a:after, .main_navigation_trigger, .social_navigation > ul > li > a, .share_navigation ul > li > a, .share_navigation ul > li > a:after, .list_type_4 .item .thumb .lazyload_wrapper img, .list_type_4_pagination .pag_link, .ajax_article, .article_wrapper .date a,
.article_wrapper .date span,
.article_wrapper .category a,
.article_wrapper .category span, .article_wrapper .date a:after,
.article_wrapper .category a:after, .header, .header:before, .header .center_content, .footer .link:after, .footer .email_link {
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVENESS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.only_desktop {
  display: none; }
  @media screen and (min-width: 769px) {
    .only_desktop {
      display: block; } }

.only_mobile {
  display: none; }
  @media screen and (max-width: 768px) {
    .only_mobile {
      display: block; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* KEYFRAMES -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* --- Spin --- */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRID SYSTEM ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* Widths */
  /* Column */
  /* Gutter 12px */
  /* Gutter 24px */
  /* Gutter 48px */
  /* Gutter 96px */ }
  @media screen and (max-width: 768px) {
    .grid {
      margin-left: 0 !important;
      margin-right: 0 !important; } }
  .grid .width_01-12 {
    width: 8.33%; }
  .grid .width_02-12 {
    width: 16.66%; }
  .grid .width_03-12 {
    width: 25%; }
  .grid .width_04-12 {
    width: 33.33%; }
  .grid .width_05-12 {
    width: 41.66%; }
  .grid .width_06-12 {
    width: 50%; }
  .grid .width_07-12 {
    width: 58.33%; }
  .grid .width_08-12 {
    width: 66.66%; }
  .grid .width_09-12 {
    width: 75%; }
  .grid .width_10-12 {
    width: 83.33%; }
  .grid .width_11-12 {
    width: 91.66%; }
  .grid .width_12-12 {
    width: 100%; }
  .grid .width_01-05 {
    width: 20%; }
  .grid .width_02-05 {
    width: 40%; }
  .grid .width_03-05 {
    width: 60%; }
  .grid .width_04-05 {
    width: 80%; }
  .grid .width_01-07 {
    width: 14.28%; }
  .grid .width_01-08 {
    width: 12.5%; }
  .grid .column {
    flex-direction: column;
    box-sizing: border-box; }
    @media screen and (max-width: 768px) {
      .grid .column {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100%; } }
  .grid.gutter_12 {
    margin-left: -6px;
    margin-right: -6px; }
    .grid.gutter_12 > .column {
      padding-left: 6px;
      padding-right: 6px; }
  .grid.gutter_24 {
    margin-left: -12px;
    margin-right: -12px; }
    .grid.gutter_24 > .column {
      padding-left: 12px;
      padding-right: 12px; }
  .grid.gutter_48 {
    margin-left: -24px;
    margin-right: -24px; }
    .grid.gutter_48 > .column {
      padding-left: 24px;
      padding-right: 24px; }
  .grid.gutter_96 {
    margin-left: -48px;
    margin-right: -48px; }
    .grid.gutter_96 > .column {
      padding-left: 48px;
      padding-right: 48px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (RESET) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  line-height: 1.6;
  font-family: "gilroy-regular", sans-serif;
  font-size: 100%;
  border: 0 solid;
  border-radius: 0;
  appearance: none; }

[type=tel],
[type=text],
[type=email],
[type=password] {
  display: block;
  width: 100%;
  background: transparent; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  appearance: button; }

[type=submit] {
  width: 100%;
  cursor: pointer;
  overflow: visible; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

fieldset {
  margin: 0;
  padding: 0;
  border: none; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  display: block;
  width: 100%;
  background: transparent;
  resize: none;
  overflow: auto;
  box-sizing: border-box; }

[type="checkbox"],
[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  appearance: textfield; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

[hidden] {
  display: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* STYLE ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form {
  /* --- Label --- */
  /* --- Input text --- */
  /* --- Field select --- */
  /* --- Radio & Checkbox --- */ }
  .form label.field_label {
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
  .form label.in_field_label {
    box-sizing: border-box;
    cursor: pointer; }
  .form label em {
    flex: 1;
    padding-left: 12px;
    font-size: 14px;
    text-align: right;
    opacity: .48; }
  .form .field_select select {
    width: 100%;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer; }
  .form .input_radio,
  .form .input_checkbox {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; }
    .form .input_radio input[type=radio],
    .form .input_radio input[type=checkbox],
    .form .input_checkbox input[type=radio],
    .form .input_checkbox input[type=checkbox] {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      cursor: pointer; }
    .form .input_radio span,
    .form .input_checkbox span {
      display: block;
      text-align: center;
      box-sizing: border-box; }
      .form .input_radio span svg,
      .form .input_checkbox span svg {
        display: block;
        opacity: 0; }
    .form .input_radio label,
    .form .input_checkbox label {
      flex: 1;
      flex-direction: column;
      margin: 0;
      padding: 0;
      line-height: 1.4;
      cursor: pointer; }
    .form .input_radio input[type=radio]:checked + span svg,
    .form .input_radio input[type=checkbox]:checked + span svg,
    .form .input_checkbox input[type=radio]:checked + span svg,
    .form .input_checkbox input[type=checkbox]:checked + span svg {
      opacity: 1; }
  .form .input_radio span {
    border-radius: 100%; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* VALIDATION ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form_alert {
  margin-top: -1px;
  padding-bottom: 2px;
  text-align: center; }
  .form_alert.is-valid {
    color: #00B16A; }
  .form_alert.is-invalid {
    color: #EE5533; }

/* --- Feedback --- */
.field_feedback {
  padding: 0 12px;
  max-height: 0;
  line-height: 1.2;
  color: #EE5533;
  background: rgba(238, 85, 51, 0.12);
  overflow: hidden;
  opacity: 0; }

.input_radio + .field_feedback,
.input_checkbox + .field_feedback {
  margin-top: 12px; }

/* --- Badges --- */
.badge_valid,
.badge_invalid {
  position: absolute;
  top: 0;
  right: 32px;
  bottom: 0;
  z-index: 2;
  width: 16px;
  opacity: 0; }
  @media screen and (max-width: 768px) {
    .badge_valid,
    .badge_invalid {
      right: 18px; } }
  .badge_valid svg,
  .badge_invalid svg {
    display: block;
    padding-top: 33px;
    width: 16px;
    height: 16px;
    fill: #EE5533; }
    @media screen and (max-width: 768px) {
      .badge_valid svg,
      .badge_invalid svg {
        padding-top: 18px; } }

.input_radio .badge_valid,
.input_radio .badge_invalid,
.input_checkbox .badge_valid,
.input_checkbox .badge_invalid {
  top: 0;
  left: 25px;
  right: auto;
  width: 12px;
  height: 12px;
  transform: translateY(-50%); }
  .input_radio .badge_valid svg,
  .input_radio .badge_invalid svg,
  .input_checkbox .badge_valid svg,
  .input_checkbox .badge_invalid svg {
    display: none; }

/* --- Valid --- */
.is-valid .field_feedback,
.is-valid .icon.badge_invalid {
  opacity: 0; }

.is-valid .icon.badge_valid {
  opacity: 1; }

/* --- Invalid --- */
.is-invalid .icon.badge_valid {
  opacity: 0; }

.is-invalid .field_feedback,
.is-invalid .icon.badge_invalid {
  opacity: 1; }

.is-invalid .field_feedback {
  margin-top: 3px;
  padding: 12px;
  max-height: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.no-js {
  /* --- Select --- */ }
  .no-js .field_select span {
    display: none; }
  .no-js .field_select select {
    position: static;
    padding: 16px 20px 17px;
    border: 2px solid rgba(20, 21, 26, 0.12);
    opacity: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
h1, .heading_typo_1, .hero_in .heading .center_content h2, .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .list_type_3 .item .title, .list_type_3 .item .hero_in .heading .center_content .title, h2, .heading_typo_2, .list_type_3 .item .title, h3, .heading_typo_3, h4, h5, h6 {
  margin: 0; }

h1, .heading_typo_1, .hero_in .heading .center_content h2, .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .list_type_3 .item .title, .list_type_3 .item .hero_in .heading .center_content .title {
  line-height: 1.3;
  font-size: 48px;
  font-weight: 400; }
  @media screen and (max-width: 768px) {
    h1, .heading_typo_1, .hero_in .heading .center_content h2, .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .list_type_3 .item .title, .list_type_3 .item .hero_in .heading .center_content .title {
      font-size: 32px; } }

h2, .heading_typo_2, .list_type_3 .item .title {
  line-height: 1.4;
  font-size: 32px;
  font-weight: 400; }
  @media screen and (max-width: 768px) {
    h2, .heading_typo_2, .list_type_3 .item .title {
      font-size: 24px; } }

h3, .heading_typo_3 {
  line-height: 1.5;
  font-size: 24px;
  font-weight: 400; }
  @media screen and (max-width: 768px) {
    h3, .heading_typo_3 {
      font-size: 18px; } }

h4 {
  font-size: 18px;
  font-weight: 400; }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 16px; } }

h5,
h6 {
  line-height: 1.6;
  font-size: 16px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HR LINES --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
hr {
  height: 0;
  overflow: visible;
  box-sizing: content-box; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANCHORS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
a {
  color: #14151A;
  text-decoration: none;
  text-decoration-skip: objects;
  background-color: transparent; }
  a:active, a:hover {
    outline-width: 0; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT BOLD -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
b,
strong {
  font-weight: inherit;
  font-weight: bold; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT ITALIC ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
i,
em,
dfn {
  font-style: italic; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT HIGHLIGHTED ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
mark {
  color: #333;
  background: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TEXT SMALL ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
small,
.small {
  font-size: 14px; }

sub,
sup {
  position: relative;
  line-height: 0;
  font-size: 75%;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IMAGES ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
figure {
  display: block;
  margin: 0; }
  figure figcaption {
    display: block; }

img {
  width: auto\9;
  height: auto;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic; }
  img.align_left {
    float: left; }
  img.align_right {
    float: right; }

svg:not(:root) {
  overflow: hidden; }

/* FUNCTIONS */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* MODAL WINDOW ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-modal_wrapper {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.88); }
  @media screen and (max-width: 768px) {
    .js-modal_wrapper:not(.modal_flash_message) {
      background: #FFF;
      overflow: auto; }
      .js-modal_wrapper:not(.modal_flash_message) .js-modal_content {
        position: static;
        top: 0;
        left: 0;
        padding: 0;
        width: auto;
        max-width: none;
        min-height: 0;
        background: none;
        overflow: auto;
        transform: none; }
    .js-modal_wrapper.modal_flash_message .js-modal_content {
      padding: 24px; }
      .js-modal_wrapper.modal_flash_message .js-modal_content.wXSmall {
        max-width: calc(440px + 48px); } }
  .js-modal_wrapper .js-modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    padding: 0 72px;
    width: 100%;
    box-sizing: border-box;
    transform: translate(-50%, -50%); }
    .js-modal_wrapper .js-modal_content.wXSmall {
      max-width: calc(440px + 144px); }
  .js-modal_wrapper .js-modal_trigger {
    display: inline-block;
    padding: 18px 24px;
    min-width: 100px; }
    @media screen and (min-width: 769px) {
      .js-modal_wrapper .js-modal_trigger:hover, .js-modal_wrapper .js-modal_trigger:focus {
        color: #F2F2F2;
        background: #b62e0f; } }
  .js-modal_wrapper.fixedHeight {
    overflow: auto; }
    .js-modal_wrapper.fixedHeight .js-modal_content {
      position: relative;
      top: auto;
      left: auto;
      margin: 24px auto;
      transform: none; }
      @media screen and (max-width: 768px) {
        .js-modal_wrapper.fixedHeight .js-modal_content {
          margin: 0; } }
  .js-modal_wrapper.start_open {
    display: block; }
  .js-modal_wrapper.modal_error .js-modal_content {
    border-top: 4px solid #EE5533; }
  .js-modal_wrapper.modal_alert .js-modal_content {
    border-top: 4px solid #F89406; }
  .js-modal_wrapper.modal_valid .js-modal_content {
    border-top: 4px solid #00B16A; }
  .js-modal_wrapper.modal_update .js-modal_content {
    border-top: 4px solid #1E8BC3; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-modal_trigger_no-js {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important; }

.no-js .js-modal_wrapper:target {
  display: block; }

.no-js .js-modal_trigger_no-js {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important; }
  .no-js .js-modal_trigger_no-js + a {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVE IMAGES ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.responsiveImg img {
  position: absolute;
  left: -9999px;
  max-width: none;
  opacity: 0; }

/* PLUGINS */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAZYLOAD --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.lazyload_wrapper picture,
.lazyload_wrapper img {
  opacity: 0; }
  .lazyload_wrapper picture.lazyloaded,
  .lazyload_wrapper img.lazyloaded {
    opacity: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COOKIE NOTIFICATION ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.cookie_notification {
  display: none;
  position: fixed;
  left: 24px;
  bottom: 81px;
  z-index: 103;
  padding: 32px;
  width: 100%;
  max-width: 200px;
  line-height: 1.4;
  color: #14151A;
  font-size: 14px;
  text-align: center;
  background: #FFF; }
  @media screen and (min-width: 769px) {
    .cookie_notification {
      bottom: 24px; } }
  .cookie_notification p > a {
    color: #14151A; }
    .cookie_notification p > a:after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #14151A; }
    @media screen and (min-width: 769px) {
      .cookie_notification p > a:hover:after, .cookie_notification p > a:focus:after {
        opacity: 0; } }
  .cookie_notification .cookie_close.anchor {
    display: inline-block;
    margin: 12px auto 0;
    color: #14151A;
    font-family: 'gilroy-bold', sans-serif;
    text-decoration: none; }
    @media screen and (min-width: 769px) {
      .cookie_notification .cookie_close.anchor:hover, .cookie_notification .cookie_close.anchor:focus {
        color: #EE5533; } }
  .cookie_notification .cookie_close.icon {
    top: 0;
    right: 0;
    padding: 12px;
    width: 12px;
    height: 12px; }
    .cookie_notification .cookie_close.icon svg {
      display: block;
      width: 12px;
      height: 12px;
      fill: #14151A; }
    @media screen and (min-width: 769px) {
      .cookie_notification .cookie_close.icon:hover svg,
      .cookie_notification .cookie_close.icon:focus svg {
        fill: #EE5533; } }

/* COMPONENTS */
body {
  transition-property: margin;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out; }

@media screen and (max-width: 768px) {
  .alert-margin {
    margin-top: 0 !important; } }

.alert_banner_con {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #EE5533;
  min-height: 80px;
  z-index: 999;
  overflow: hidden;
  padding: 20px 96px;
  box-sizing: border-box; }
  @media screen and (min-width: 769px) {
    .alert_banner_con.is-sticky {
      padding: 20px 32px; } }
  @media screen and (max-width: 768px) {
    .alert_banner_con.is-sticky {
      transform: translateY(-100%); } }
  .alert_banner_con.is-sticky .alert_banner_inner {
    max-width: 100%; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .alert_banner_con {
      padding: 20px 48px; } }
  @media screen and (max-width: 768px) {
    .alert_banner_con {
      padding: 20px 24px; } }
  .alert_banner_con::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    background-color: #f0694b;
    z-index: 0; }
    @media screen and (max-width: 768px) {
      .alert_banner_con::before {
        clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%); } }
  .alert_banner_con .alert_banner_inner {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    position: relative; }
    @media screen and (max-width: 768px) {
      .alert_banner_con .alert_banner_inner {
        flex-wrap: wrap; } }
    .alert_banner_con .alert_banner_inner .alert_banner_inner__textarea {
      padding-right: 20px; }
      .alert_banner_con .alert_banner_inner .alert_banner_inner__textarea p {
        color: #F2F2F2; }
      .alert_banner_con .alert_banner_inner .alert_banner_inner__textarea .title {
        font-family: gilroy-extrabold,sans-serif;
        font-size: 20px; }
        @media screen and (max-width: 768px) {
          .alert_banner_con .alert_banner_inner .alert_banner_inner__textarea .title {
            margin-bottom: 10px;
            font-size: 18px; } }
      .alert_banner_con .alert_banner_inner .alert_banner_inner__textarea .body {
        font-size: 18px; }
        @media screen and (max-width: 768px) {
          .alert_banner_con .alert_banner_inner .alert_banner_inner__textarea .body {
            margin-bottom: 10px;
            font-size: 16px; } }
    .alert_banner_con .alert_banner_inner .button {
      background-color: #F2F2F2;
      padding-top: 15px;
      padding-bottom: 15px;
      color: #EE5533;
      min-width: 190px;
      box-sizing: border-box; }
      @media screen and (max-width: 768px) {
        .alert_banner_con .alert_banner_inner .button {
          width: 100%; } }
      .alert_banner_con .alert_banner_inner .button svg {
        fill: #EE5533; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (min-width: 769px) {
  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; }
    .buttons-wrapper .button:not(:last-child) {
      margin-right: 12px; } }

@media screen and (max-width: 768px) {
  .buttons-wrapper {
    padding-top: 48px; }
    .buttons-wrapper .button:not(:first-child) {
      margin-top: 12px; } }

.button {
  transition: color 300ms ease-in-out, background 300ms ease-in-out, border 300ms ease-in-out;
  display: inline-block; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 1 & 2) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.button_type_1,
.button_type_2 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  color: #F2F2F2; }
  .button_type_1 svg,
  .button_type_2 svg {
    padding-left: 16px;
    width: 18px;
    height: 18px;
    fill: #FFF; }
  @media screen and (min-width: 769px) {
    .button_type_1:hover svg, .button_type_1:focus svg,
    .button_type_2:hover svg,
    .button_type_2:focus svg {
      transform: translate(6px); } }

.button_type_1 {
  padding: 24px 32px;
  font-size: 18px;
  font-family: 'gilroy-medium', sans-serif;
  background: #EE5533; }
  @media screen and (min-width: 769px) {
    .button_type_1:hover, .button_type_1:focus {
      background: #BA3314; } }
  @media screen and (max-width: 768px) {
    .button_type_1 {
      padding: 18px 24px; } }

.button_type_2 {
  padding: 24px 38px;
  font-size: 22px;
  font-family: 'gilroy-semibold', sans-serif;
  background: rgba(242, 242, 242, 0.06); }
  @media screen and (min-width: 769px) {
    .button_type_2:hover, .button_type_2:focus {
      background: #EE5533; } }
  @media screen and (max-width: 768px) {
    .button_type_2 {
      justify-content: space-between;
      padding: 18px 24px;
      width: 100%;
      font-size: 18px;
      box-sizing: border-box; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 3) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.button_type_3 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 25px 32px;
  color: #14151A;
  font-size: 18px;
  font-family: 'gilroy-extrabold', sans-serif;
  text-transform: uppercase;
  background-color: #F2F2F2; }
  @media screen and (max-width: 768px) {
    .button_type_3 {
      padding: 16px 24px;
      font-size: 16px; } }
  .button_type_3 span:first-child {
    transform: translateX(16px); }
    @media screen and (max-width: 768px) {
      .button_type_3 span:first-child {
        margin: 0 auto;
        transform: none; } }
  .button_type_3 .icon {
    overflow: hidden;
    width: 32px;
    height: 18px;
    opacity: 0;
    transform: translateX(-16px); }
    @media screen and (max-width: 768px) {
      .button_type_3 .icon {
        display: none; } }
    .button_type_3 .icon svg {
      display: block;
      padding-left: 16px;
      width: 16px;
      height: 18px;
      fill: #14151A; }
  @media screen and (min-width: 769px) {
    .button_type_3:hover span:first-child, .button_type_3:focus span:first-child {
      transform: translateX(0); }
    .button_type_3:hover .icon, .button_type_3:focus .icon {
      opacity: 1;
      transform: translateX(0); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 4) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.button_type_4 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 24px 38px;
  color: #EE5533 !important;
  font-size: 18px;
  font-family: 'gilroy-semibold', sans-serif;
  background: none !important;
  border: 2px solid #EE5533;
  box-sizing: border-box; }
  .button_type_4 svg {
    padding-left: 16px;
    width: 18px;
    height: 18px;
    fill: #EE5533; }
  @media screen and (min-width: 769px) {
    .button_type_4:hover, .button_type_4:focus {
      color: #FFF !important;
      border-color: #FFF; }
      .button_type_4:hover svg, .button_type_4:focus svg {
        fill: #FFF;
        transform: translate(6px); } }
  @media screen and (max-width: 768px) {
    .button_type_4 {
      justify-content: space-between;
      padding: 18px 24px;
      width: 100%; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (SCROLL DOWN) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.scroll_down {
  display: block;
  margin: -12px;
  padding: 12px;
  width: 28px;
  height: 28px; }
  .scroll_down svg {
    display: block;
    width: 28px;
    height: 28px; }
    .scroll_down svg circle {
      transition: transform 300ms ease-in-out; }
  @media screen and (min-width: 769px) {
    .scroll_down:hover svg circle,
    .scroll_down:focus svg circle {
      transform: translateY(14px); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (SCROLL UP) ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.scroll_up {
  display: block;
  margin: -12px;
  padding: 12px;
  width: 28px;
  height: 28px; }
  .scroll_up svg {
    display: block;
    width: 28px;
    height: 28px;
    transform: rotate(-90deg); }
  @media screen and (min-width: 769px) {
    .scroll_up:hover svg,
    .scroll_up:focus svg {
      transform: rotate(-90deg) translateX(6px); } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (PREV / NEXT) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.prev_type_1,
.next_type_1 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 2px solid rgba(242, 242, 242, 0.12);
  border-radius: 100%;
  box-sizing: border-box; }
  .prev_type_1 svg,
  .next_type_1 svg {
    display: block;
    width: 14px;
    height: 14px;
    fill: rgba(242, 242, 242, 0.12); }
  @media screen and (min-width: 769px) {
    .prev_type_1:not(.disabled):hover, .prev_type_1:not(.disabled):focus,
    .next_type_1:not(.disabled):hover,
    .next_type_1:not(.disabled):focus {
      border: 2px solid #F2F2F2; }
      .prev_type_1:not(.disabled):hover svg, .prev_type_1:not(.disabled):focus svg,
      .next_type_1:not(.disabled):hover svg,
      .next_type_1:not(.disabled):focus svg {
        fill: #F2F2F2; } }
  .prev_type_1.disabled,
  .next_type_1.disabled {
    cursor: default; }

.prev_type_1 svg {
  transform: rotate(180deg); }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (CLOSE) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.close_type_1 {
  position: fixed;
  z-index: 100;
  background: #14151A; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .close_type_1 {
      top: 34px;
      right: 48px; } }
  @media screen and (max-width: 768px) {
    .close_type_1 {
      top: 0;
      right: 0;
      width: 48px;
      height: 48px; } }
  .close_type_1 svg {
    display: block;
    padding: 25px;
    width: 14px;
    height: 14px;
    fill: rgba(242, 242, 242, 0.32); }
    @media screen and (max-width: 768px) {
      .close_type_1 svg {
        padding: 17px;
        fill: #F2F2F2; } }
  @media screen and (min-width: 769px) {
    .close_type_1 {
      top: 24px;
      right: 24px;
      width: 64px;
      height: 64px;
      border-radius: 100%; }
      .close_type_1:hover svg,
      .close_type_1:focus svg {
        fill: #F2F2F2; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 1) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box_type_1 {
  padding: 96px 0; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .box_type_1 {
      padding: 48px 0; } }
  @media screen and (max-width: 768px) {
    .box_type_1 {
      padding: 8px 0; } }
  @media screen and (max-height: 768px) and (min-width: 769px) and (orientation: landscape) {
    .box_type_1 {
      padding: 48px 96px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPE 0) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_type_0, .hero .heading h1 span, .hero .heading .heading_typo_1 span, .hero .hero_in .heading .center_content h2 span, .hero_in .hero .heading .center_content h2 span, .hero .hero_in .heading .center_content .heading_typo_2 span, .hero_in .hero .heading .center_content .heading_typo_2 span, .hero .hero_in .heading .center_content .list_type_3 .item .title span, .hero_in .hero .heading .center_content .list_type_3 .item .title span, .hero .list_type_3 .item .hero_in .heading .center_content .title span, .list_type_3 .item .hero_in .hero .heading .center_content .title span {
  display: block;
  line-height: 1;
  font-family: 'gilroy-extrabold', sans-serif; }
  .heading_type_0, .hero .heading h1 span, .hero .heading .heading_typo_1 span, .hero .hero_in .heading .center_content h2 span, .hero_in .hero .heading .center_content h2 span, .hero .hero_in .heading .center_content .heading_typo_2 span, .hero_in .hero .heading .center_content .heading_typo_2 span, .hero .hero_in .heading .center_content .list_type_3 .item .title span, .hero_in .hero .heading .center_content .list_type_3 .item .title span, .hero .list_type_3 .item .hero_in .heading .center_content .title span, .list_type_3 .item .hero_in .hero .heading .center_content .title span {
    font-size: 32px; }
    @media screen and (min-width: 360px) {
      .heading_type_0, .hero .heading h1 span, .hero .heading .heading_typo_1 span, .hero .hero_in .heading .center_content h2 span, .hero_in .hero .heading .center_content h2 span, .hero .hero_in .heading .center_content .heading_typo_2 span, .hero_in .hero .heading .center_content .heading_typo_2 span, .hero .hero_in .heading .center_content .list_type_3 .item .title span, .hero_in .hero .heading .center_content .list_type_3 .item .title span, .hero .list_type_3 .item .hero_in .heading .center_content .title span, .list_type_3 .item .hero_in .hero .heading .center_content .title span {
        font-size: calc(32px + 160 * ((100vw - 360px) / 1240)); } }
    @media screen and (min-width: 1600px) {
      .heading_type_0, .hero .heading h1 span, .hero .heading .heading_typo_1 span, .hero .hero_in .heading .center_content h2 span, .hero_in .hero .heading .center_content h2 span, .hero .hero_in .heading .center_content .heading_typo_2 span, .hero_in .hero .heading .center_content .heading_typo_2 span, .hero .hero_in .heading .center_content .list_type_3 .item .title span, .hero_in .hero .heading .center_content .list_type_3 .item .title span, .hero .list_type_3 .item .hero_in .heading .center_content .title span, .list_type_3 .item .hero_in .hero .heading .center_content .title span {
        font-size: 192px; } }
  .heading_type_0.long, .hero .heading h1 span.long, .hero .heading .heading_typo_1 span.long, .hero .hero_in .heading .center_content h2 span.long, .hero_in .hero .heading .center_content h2 span.long, .hero .hero_in .heading .center_content .heading_typo_2 span.long, .hero_in .hero .heading .center_content .heading_typo_2 span.long, .hero .hero_in .heading .center_content .list_type_3 .item .title span.long, .hero_in .hero .heading .center_content .list_type_3 .item .title span.long, .hero .list_type_3 .item .hero_in .heading .center_content .title span.long, .list_type_3 .item .hero_in .hero .heading .center_content .title span.long {
    margin: 0 auto;
    padding-top: 12px;
    width: 100%;
    max-width: 1220px; }
    .heading_type_0.long, .hero .heading h1 span.long, .hero .heading .heading_typo_1 span.long, .hero .hero_in .heading .center_content h2 span.long, .hero_in .hero .heading .center_content h2 span.long, .hero .hero_in .heading .center_content .heading_typo_2 span.long, .hero_in .hero .heading .center_content .heading_typo_2 span.long, .hero .hero_in .heading .center_content .list_type_3 .item .title span.long, .hero_in .hero .heading .center_content .list_type_3 .item .title span.long, .hero .list_type_3 .item .hero_in .heading .center_content .title span.long, .list_type_3 .item .hero_in .hero .heading .center_content .title span.long {
      font-size: 32px; }
      @media screen and (min-width: 360px) {
        .heading_type_0.long, .hero .heading h1 span.long, .hero .heading .heading_typo_1 span.long, .hero .hero_in .heading .center_content h2 span.long, .hero_in .hero .heading .center_content h2 span.long, .hero .hero_in .heading .center_content .heading_typo_2 span.long, .hero_in .hero .heading .center_content .heading_typo_2 span.long, .hero .hero_in .heading .center_content .list_type_3 .item .title span.long, .hero_in .hero .heading .center_content .list_type_3 .item .title span.long, .hero .list_type_3 .item .hero_in .heading .center_content .title span.long, .list_type_3 .item .hero_in .hero .heading .center_content .title span.long {
          font-size: calc(32px + 64 * ((100vw - 360px) / 1240)); } }
      @media screen and (min-width: 1600px) {
        .heading_type_0.long, .hero .heading h1 span.long, .hero .heading .heading_typo_1 span.long, .hero .hero_in .heading .center_content h2 span.long, .hero_in .hero .heading .center_content h2 span.long, .hero .hero_in .heading .center_content .heading_typo_2 span.long, .hero_in .hero .heading .center_content .heading_typo_2 span.long, .hero .hero_in .heading .center_content .list_type_3 .item .title span.long, .hero_in .hero .heading .center_content .list_type_3 .item .title span.long, .hero .list_type_3 .item .hero_in .heading .center_content .title span.long, .list_type_3 .item .hero_in .hero .heading .center_content .title span.long {
          font-size: 96px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPE 1) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_type_1, .typography .pre, .footer h2, .footer .heading_typo_2, .footer .list_type_3 .item .title, .list_type_3 .item .footer .title {
  display: block;
  margin-bottom: -.5em;
  font-size: 18px;
  font-family: 'gilroy-extrabold', sans-serif;
  text-transform: uppercase; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPO 1) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_typo_1, .hero_in .heading .center_content h2, .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .list_type_3 .item .title, .list_type_3 .item .hero_in .heading .center_content .title {
  font-family: 'gilroy-medium', sans-serif; }
  @media screen and (max-width: 768px) {
    .heading_typo_1, .hero_in .heading .center_content h2, .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .list_type_3 .item .title, .list_type_3 .item .hero_in .heading .center_content .title {
      font-size: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPO 2) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_typo_2, .list_type_3 .item .title {
  font-family: 'gilroy-semibold', sans-serif; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADING (TYPO 3) ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.heading_typo_3 {
  font-family: 'gilroy-medium', sans-serif; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form_type_1 {
  padding-top: 48px;
  /* --- Field --- */
  /* --- Input text --- */
  /* --- Select --- */
  /* --- Input checkbox & radio --- */
  /* --- Feedback --- */ }
  @media screen and (max-width: 768px) {
    .form_type_1 {
      padding-top: 24px; } }
  .form_type_1 .field-wrapper_hidden {
    max-height: 0;
    overflow: hidden; }
  .form_type_1 .field label {
    font-size: 22px; }
    @media screen and (max-width: 768px) {
      .form_type_1 .field label {
        font-size: 16px; } }
    .form_type_1 .field label.field_label {
      padding: 12px 0 6px; }
    .form_type_1 .field label.in_field_label {
      padding: 24px 32px;
      color: #EE5533; }
      @media screen and (max-width: 768px) {
        .form_type_1 .field label.in_field_label {
          padding: 14px 18px; } }
  .form_type_1 .field label a {
    color: #14151A;
    text-decoration: underline; }
    @media screen and (min-width: 769px) {
      .form_type_1 .field label a:hover, .form_type_1 .field label a:focus {
        text-decoration: none; } }
  .form_type_1 input[type=tel],
  .form_type_1 input[type=text],
  .form_type_1 input[type=email],
  .form_type_1 input[type=password],
  .form_type_1 textarea {
    padding: 24px 32px;
    color: #EE5533;
    font-size: 22px;
    box-sizing: border-box;
    background: #14151A; }
    @media screen and (max-width: 768px) {
      .form_type_1 input[type=tel],
      .form_type_1 input[type=text],
      .form_type_1 input[type=email],
      .form_type_1 input[type=password],
      .form_type_1 textarea {
        padding: 14px 18px;
        font-size: 16px; } }
    .form_type_1 input[type=tel]:not([value=""]) + .in_field_label,
    .form_type_1 input[type=tel]:focus + .in_field_label,
    .form_type_1 input[type=text]:not([value=""]) + .in_field_label,
    .form_type_1 input[type=text]:focus + .in_field_label,
    .form_type_1 input[type=email]:not([value=""]) + .in_field_label,
    .form_type_1 input[type=email]:focus + .in_field_label,
    .form_type_1 input[type=password]:not([value=""]) + .in_field_label,
    .form_type_1 input[type=password]:focus + .in_field_label,
    .form_type_1 textarea:not([value=""]) + .in_field_label,
    .form_type_1 textarea:focus + .in_field_label {
      padding: 12px 32px;
      font-size: 9px; }
      @media screen and (max-width: 768px) {
        .form_type_1 input[type=tel]:not([value=""]) + .in_field_label,
        .form_type_1 input[type=tel]:focus + .in_field_label,
        .form_type_1 input[type=text]:not([value=""]) + .in_field_label,
        .form_type_1 input[type=text]:focus + .in_field_label,
        .form_type_1 input[type=email]:not([value=""]) + .in_field_label,
        .form_type_1 input[type=email]:focus + .in_field_label,
        .form_type_1 input[type=password]:not([value=""]) + .in_field_label,
        .form_type_1 input[type=password]:focus + .in_field_label,
        .form_type_1 textarea:not([value=""]) + .in_field_label,
        .form_type_1 textarea:focus + .in_field_label {
          padding: 7px 18px;
          font-size: 8px; } }
  .form_type_1 .field_select select {
    padding: 24px 32px 24px 66px;
    color: #EE5533;
    font-size: 22px;
    background: #14151A;
    box-sizing: border-box; }
    @media screen and (max-width: 768px) {
      .form_type_1 .field_select select {
        padding: 14px 18px 14px 41px;
        font-size: 16px; } }
    .form_type_1 .field_select select option {
      color: #14151A;
      font-size: 16px;
      background: #F2F2F2; }
    .form_type_1 .field_select select:hover + .arrow {
      transform: translateY(-50%) rotate(-90deg); }
  .form_type_1 .field_select .arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    z-index: 2;
    width: 18px;
    height: 18px;
    fill: #EE5533;
    transform: translateY(-50%) rotate(90deg); }
    @media screen and (max-width: 768px) {
      .form_type_1 .field_select .arrow {
        left: 18px;
        width: 14px;
        height: 14px; } }
  .form_type_1 .input_radio,
  .form_type_1 .input_checkbox {
    margin-top: 24px; }
    .form_type_1 .input_radio input[type=radio],
    .form_type_1 .input_radio input[type=checkbox],
    .form_type_1 .input_checkbox input[type=radio],
    .form_type_1 .input_checkbox input[type=checkbox] {
      width: 32px;
      height: 32px; }
      @media screen and (min-width: 769px) {
        .form_type_1 .input_radio input[type=radio]:not(:focus):hover + span,
        .form_type_1 .input_radio input[type=checkbox]:not(:focus):hover + span,
        .form_type_1 .input_checkbox input[type=radio]:not(:focus):hover + span,
        .form_type_1 .input_checkbox input[type=checkbox]:not(:focus):hover + span {
          border: 1px solid rgba(238, 85, 51, 0.24); } }
      .form_type_1 .input_radio input[type=radio]:focus + span,
      .form_type_1 .input_radio input[type=checkbox]:focus + span,
      .form_type_1 .input_checkbox input[type=radio]:focus + span,
      .form_type_1 .input_checkbox input[type=checkbox]:focus + span {
        border: 1px solid #14151A; }
    .form_type_1 .input_radio span,
    .form_type_1 .input_checkbox span {
      margin-right: 14px;
      width: 32px;
      height: 32px;
      background: #FFF;
      border: 1px solid rgba(238, 85, 51, 0.12); }
      .form_type_1 .input_radio span svg,
      .form_type_1 .input_checkbox span svg {
        padding: 9px;
        width: 12px;
        height: 12px;
        fill: #EE5533; }
  .form_type_1 ::placeholder {
    color: #EE5533; }
  .form_type_1 .field_feedback {
    color: #14151A;
    background: rgba(20, 21, 26, 0.12); }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE RECAPTCHA ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.g-recaptcha {
  display: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography {
  font-size: 22px;
  color: #14151A; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .typography {
      font-size: 18px; } }
  @media screen and (max-width: 768px) {
    .typography {
      font-size: 16px; } }
  .typography h1, .typography .heading_typo_1, .typography .hero_in .heading .center_content h2, .hero_in .heading .center_content .typography h2, .typography .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .typography .heading_typo_2, .typography .hero_in .heading .center_content .list_type_3 .item .title, .hero_in .heading .center_content .list_type_3 .item .typography .title, .typography .list_type_3 .item .hero_in .heading .center_content .title, .list_type_3 .item .hero_in .heading .center_content .typography .title, .typography h2, .typography .heading_typo_2, .typography .list_type_3 .item .title, .list_type_3 .item .typography .title, .typography h3, .typography .heading_typo_3, .typography h4, .typography h5, .typography h6, .typography p, .typography ul, .typography ol {
    padding-top: 1.5em; }
    @media screen and (max-width: 768px) {
      .typography h1, .typography .heading_typo_1, .typography .hero_in .heading .center_content h2, .hero_in .heading .center_content .typography h2, .typography .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .typography .heading_typo_2, .typography .hero_in .heading .center_content .list_type_3 .item .title, .hero_in .heading .center_content .list_type_3 .item .typography .title, .typography .list_type_3 .item .hero_in .heading .center_content .title, .list_type_3 .item .hero_in .heading .center_content .typography .title, .typography h2, .typography .heading_typo_2, .typography .list_type_3 .item .title, .list_type_3 .item .typography .title, .typography h3, .typography .heading_typo_3, .typography h4, .typography h5, .typography h6, .typography p, .typography ul, .typography ol {
        padding-top: 1em; } }
    .typography h1:first-child, .typography .heading_typo_1:first-child, .typography .hero_in .heading .center_content h2:first-child, .hero_in .heading .center_content .typography h2:first-child, .typography .hero_in .heading .center_content .heading_typo_2:first-child, .hero_in .heading .center_content .typography .heading_typo_2:first-child, .typography .hero_in .heading .center_content .list_type_3 .item .title:first-child, .hero_in .heading .center_content .list_type_3 .item .typography .title:first-child, .typography .list_type_3 .item .hero_in .heading .center_content .title:first-child, .list_type_3 .item .hero_in .heading .center_content .typography .title:first-child, .typography h2:first-child, .typography .heading_typo_2:first-child, .typography .list_type_3 .item .title:first-child, .list_type_3 .item .typography .title:first-child, .typography h3:first-child, .typography .heading_typo_3:first-child, .typography h4:first-child, .typography h5:first-child, .typography h6:first-child, .typography p:first-child, .typography ul:first-child, .typography ol:first-child {
      padding-top: 0; }
  .typography h1, .typography .heading_typo_1, .typography .hero_in .heading .center_content h2, .hero_in .heading .center_content .typography h2, .typography .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .typography .heading_typo_2, .typography .hero_in .heading .center_content .list_type_3 .item .title, .hero_in .heading .center_content .list_type_3 .item .typography .title, .typography .list_type_3 .item .hero_in .heading .center_content .title, .list_type_3 .item .hero_in .heading .center_content .typography .title, .typography h2, .typography .heading_typo_2, .typography .list_type_3 .item .title, .list_type_3 .item .typography .title, .typography h3, .typography .heading_typo_3, .typography h4, .typography h5, .typography h6, .typography strong {
    color: #14151A; }
  .typography .pre {
    padding-bottom: 1em; }
  .typography ul {
    margin-top: -.5em;
    list-style: none; }
    .typography ul > li {
      padding-top: .5em;
      padding-left: 48px;
      line-height: 1.4; }
      @media screen and (max-width: 768px) {
        .typography ul > li {
          padding-left: 20px; } }
      .typography ul > li:before {
        content: "";
        top: calc(.5em + 13px);
        left: 24px;
        width: 9px;
        height: 9px;
        border-radius: 100%;
        background: #14151A; }
        @media screen and (max-width: 768px) {
          .typography ul > li:before {
            top: calc(.5em + 10px);
            left: 0;
            width: 6px;
            height: 6px; } }
  .typography p a:not(.button):after {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #14151A; }
  @media screen and (min-width: 769px) {
    .typography p a:not(.button):hover, .typography p a:not(.button):focus {
      color: #EE5533; }
      .typography p a:not(.button):hover:after, .typography p a:not(.button):focus:after {
        background: #EE5533; } }
  .typography p img {
    max-width: 100%; }
  .typography small {
    font-size: 16px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY INVERTED ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_inverted {
  color: rgba(242, 242, 242, 0.48); }
  .typography_inverted h1, .typography_inverted .heading_typo_1, .typography_inverted .hero_in .heading .center_content h2, .hero_in .heading .center_content .typography_inverted h2, .typography_inverted .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .typography_inverted .heading_typo_2, .typography_inverted .hero_in .heading .center_content .list_type_3 .item .title, .hero_in .heading .center_content .list_type_3 .item .typography_inverted .title, .typography_inverted .list_type_3 .item .hero_in .heading .center_content .title, .list_type_3 .item .hero_in .heading .center_content .typography_inverted .title, .typography_inverted h2, .typography_inverted .heading_typo_2, .typography_inverted .list_type_3 .item .title, .list_type_3 .item .typography_inverted .title, .typography_inverted h3, .typography_inverted .heading_typo_3, .typography_inverted h4, .typography_inverted h5, .typography_inverted h6, .typography_inverted strong {
    color: #F2F2F2; }
  .typography_inverted .pre {
    margin-bottom: 0;
    font-family: 'gilroy-bold', sans-serif; }
  .typography_inverted ul > li:before {
    background: #F2F2F2; }
  .typography_inverted p a:not(.button) {
    color: rgba(242, 242, 242, 0.48); }
    .typography_inverted p a:not(.button):after {
      background: rgba(242, 242, 242, 0.48); }
    @media screen and (min-width: 769px) {
      .typography_inverted p a:not(.button):hover, .typography_inverted p a:not(.button):focus {
        color: #F2F2F2; }
        .typography_inverted p a:not(.button):hover:after, .typography_inverted p a:not(.button):focus:after {
          background: #F2F2F2; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY INTRO ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .typography_intro {
    text-align: center; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN NAVIGATION -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main_navigation {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end; }
  .main_navigation .main_navigation-inner > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center; }
    .main_navigation .main_navigation-inner > ul > li {
      list-style: none; }
      .main_navigation .main_navigation-inner > ul > li:not(:first-child) {
        padding-left: 32px; }
      .main_navigation .main_navigation-inner > ul > li > a {
        color: #F2F2F2; }
        .main_navigation .main_navigation-inner > ul > li > a:after {
          content: "";
          bottom: -2px;
          left: 0;
          right: 0;
          height: 0;
          background: #EE5533; }
        .main_navigation .main_navigation-inner > ul > li > a span {
          position: relative;
          z-index: 2; }
      .main_navigation .main_navigation-inner > ul > li.active > a {
        color: #EE5533;
        cursor: default; }
      .main_navigation .main_navigation-inner > ul > li:not(.active) > a:hover:after,
      .main_navigation .main_navigation-inner > ul > li:not(.active) > a:focus:after {
        height: 6px; }
      .main_navigation .main_navigation-inner > ul > li.cta > a:after {
        content: "";
        height: 6px; }
      .main_navigation .main_navigation-inner > ul > li.cta:not(.active) > a:hover:after,
      .main_navigation .main_navigation-inner > ul > li.cta:not(.active) > a:focus:after,
      .main_navigation .main_navigation-inner > ul > li.cta.active > a:after {
        height: 0; }
    .main_navigation .main_navigation-inner > ul + ul {
      display: none; }
  .main_navigation .main_navigation-inner .mobBgAniCon {
    display: none; }

@media screen and (max-width: 768px) {
  .mobile_nav {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0);
    visibility: hidden;
    transition: visibility 300ms ease-in-out, background-color 300ms ease-in-out; }
    .mobile_nav .mobLineEle {
      display: none;
      position: absolute;
      top: -100%;
      height: 100%;
      left: 30px;
      width: 2px;
      background-color: #EE5533;
      animation-name: lineExpandDown;
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      z-index: 15; }
  @keyframes lineExpandDown {
    0% {
      top: -100%; }
    100% {
      top: 0; } }
    .mobile_nav .main_navigation-inner {
      max-height: 100vh;
      position: absolute;
      top: 78px;
      left: 30px;
      right: 0;
      bottom: 0;
      overflow-y: auto; }
      .mobile_nav .main_navigation-inner .navLinksUl {
        width: 100%;
        overflow-x: hidden;
        display: none;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 80px 0 80px 30px;
        height: calc(100% - 60px);
        position: relative;
        z-index: 1; } }
      @media screen and (max-width: 768px) and (max-height: 750px) {
        .mobile_nav .main_navigation-inner .navLinksUl {
          padding: 50px 0 50px 30px; } }
      @media screen and (max-width: 768px) and (max-height: 600px) {
        .mobile_nav .main_navigation-inner .navLinksUl {
          padding: 20px 0 20px 30px; } }

@media screen and (max-width: 768px) {
        .mobile_nav .main_navigation-inner .navLinksUl li {
          width: 100%;
          text-align: left;
          padding-left: 0 !important;
          font-size: 22px;
          transform: translateX(40px);
          opacity: 0;
          transition: ease-in-out 0.3s;
          padding: 10px 0; }
          .mobile_nav .main_navigation-inner .navLinksUl li a:after {
            content: "";
            height: 0 !important; }
          .mobile_nav .main_navigation-inner .navLinksUl li.show {
            transform: translateX(0);
            opacity: 1; }
      .mobile_nav .main_navigation-inner .socialsUl {
        display: none;
        transition: visibility 300ms ease-in-out, background-color 300ms ease-in-out;
        overflow-y: hidden;
        position: relative;
        height: 60px;
        padding-left: 30px;
        z-index: 1; }
        .mobile_nav .main_navigation-inner .socialsUl li {
          transform: translateY(200%);
          animation: slideUp 0.3s ease-in-out;
          animation-fill-mode: forwards; }
          .mobile_nav .main_navigation-inner .socialsUl li a::after {
            content: "";
            height: 0 !important; }
  @keyframes slideUp {
    0% {
      transform: translateY(200%); }
    100% {
      transform: translateY(0); } }
          .mobile_nav .main_navigation-inner .socialsUl li:nth-child(1) {
            animation-delay: 0.2s; }
          .mobile_nav .main_navigation-inner .socialsUl li:nth-child(2) {
            animation-delay: 0.4s; }
          .mobile_nav .main_navigation-inner .socialsUl li:nth-child(3) {
            animation-delay: 0.6s; }
          .mobile_nav .main_navigation-inner .socialsUl li:nth-child(4) {
            animation-delay: 0.8s; }
          .mobile_nav .main_navigation-inner .socialsUl li:nth-child(5) {
            animation-delay: 1s; }
          .mobile_nav .main_navigation-inner .socialsUl li:nth-child(6) {
            animation-delay: 1.2s; }
          .mobile_nav .main_navigation-inner .socialsUl li:nth-child(7) {
            animation-delay: 1.4s; }
          .mobile_nav .main_navigation-inner .socialsUl li:nth-child(8) {
            animation-delay: 1.6s; }
          .mobile_nav .main_navigation-inner .socialsUl li:nth-child(9) {
            animation-delay: 1.8s; }
          .mobile_nav .main_navigation-inner .socialsUl li:nth-child(10) {
            animation-delay: 2s; }
      .mobile_nav .main_navigation-inner .mobBgAniCon {
        position: absolute;
        top: 0;
        left: 2px;
        right: 0;
        bottom: 0;
        z-index: 0;
        overflow: hidden;
        display: none;
        opacity: 0;
        transition: 0.3s;
        transition-delay: 1s; }
        .mobile_nav .main_navigation-inner .mobBgAniCon .blockOne {
          display: block;
          position: absolute;
          top: 40%;
          left: -20%;
          width: 140%;
          background-color: #090909;
          height: 150px;
          transform: rotate(15deg);
          z-index: 3;
          animation: blockOneAni 0.4s;
          animation-fill-mode: forwards;
          animation-delay: 1s; }
  @keyframes blockOneAni {
    0% {
      height: 130px; }
    25% {
      height: 150px; }
    75% {
      height: 130px; }
    100% {
      height: 150px; } }
        .mobile_nav .main_navigation-inner .mobBgAniCon .blockTwo {
          display: block;
          position: absolute;
          top: calc(40% + 140px);
          left: -20%;
          width: 140%;
          background-color: #161616;
          height: 0;
          transform: rotate(12deg);
          z-index: 2;
          visibility: hidden;
          animation: blockTwoAni 0.5s;
          animation-fill-mode: forwards;
          animation-delay: 1.35s; }
  @keyframes blockTwoAni {
    0% {
      height: 0;
      visibility: visible; }
    25% {
      height: 140px;
      visibility: visible; }
    50% {
      height: 160px;
      visibility: visible; }
    75% {
      height: 140px;
      visibility: visible; }
    100% {
      height: 160px;
      visibility: visible; } }
        .mobile_nav .main_navigation-inner .mobBgAniCon .blockThree {
          display: block;
          position: absolute;
          top: calc(40% + 280px);
          left: -20%;
          width: 140%;
          background-color: #1B191A;
          height: 0;
          transform: rotate(9deg);
          z-index: 1;
          visibility: hidden;
          animation: blockThreeAni 0.4s ease-out;
          animation-fill-mode: forwards;
          animation-delay: 1.8s; }
  @keyframes blockThreeAni {
    0% {
      height: 0;
      visibility: visible; }
    100% {
      height: calc(100% - 40% - 150px);
      visibility: visible; } }
        .mobile_nav .main_navigation-inner .mobBgAniCon .gritLogoIcon {
          width: 150px;
          position: absolute;
          right: -75px;
          top: 50%;
          opacity: 0;
          transform: translateY(-50%) scale(1.4);
          z-index: 4;
          visibility: hidden;
          animation: navMobLogoAn 0.4s ease-out;
          animation-fill-mode: forwards;
          animation-delay: 2.1s; }
  @keyframes navMobLogoAn {
    0% {
      visibility: visible;
      transform: translateY(-50%) scale(1.4);
      opacity: 0; }
    100% {
      visibility: visible;
      transform: translateY(-50%) scale(1);
      opacity: 1; } }
    .mobile_nav.open {
      visibility: visible;
      background-color: black; }
      .mobile_nav.open .mobLineEle {
        display: block; }
      .mobile_nav.open .main_navigation-inner .navLinksUl {
        display: flex; }
      .mobile_nav.open .main_navigation-inner .socialsUl {
        display: flex; }
      .mobile_nav.open .main_navigation-inner .mobBgAniCon {
        display: flex;
        opacity: 1; } }

/* --------------------------------------------------------------------------------------------------- */
/* MAIN NAVIGATION TRIGGER --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */
.main_navigation_trigger {
  display: none;
  position: relative; }
  @media screen and (max-width: 768px) {
    .main_navigation_trigger {
      display: block;
      width: 40px;
      height: 30px; } }
  .main_navigation_trigger span {
    display: block;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 40px;
    height: 30px; }
    .main_navigation_trigger span:nth-child(1)::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 5px;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.3s ease-in-out;
      left: 0;
      background: #F2F2F2; }
    .main_navigation_trigger span:nth-child(2)::before, .main_navigation_trigger span:nth-child(2)::after {
      content: "";
      left: 0;
      display: block;
      position: absolute;
      width: 60%;
      height: 5px;
      transition: 0.1s ease-in-out;
      background: #F2F2F2;
      animation-fill-mode: forwards;
      transform: translateY(0) rotate(0); }
    .main_navigation_trigger span:nth-child(2)::before {
      top: 0;
      left: 0; }
    .main_navigation_trigger span:nth-child(2)::after {
      bottom: 0;
      left: auto;
      right: 0; }
  .main_navigation_trigger.open span:nth-child(2)::before, .main_navigation_trigger.open span:nth-child(2)::after {
    width: 100%; }
  .main_navigation_trigger.merge span:nth-child(1)::before {
    opacity: 0; }
  .main_navigation_trigger.merge span:nth-child(2)::before {
    top: 50%;
    transform: translateY(-50%) rotate(0); }
  .main_navigation_trigger.merge span:nth-child(2)::after {
    bottom: 50%;
    transform: translateY(50%) rotate(0); }
  .main_navigation_trigger.rotate span:nth-child(2)::before, .main_navigation_trigger.rotate span:nth-child(2)::after {
    width: 100%; }
  .main_navigation_trigger.rotate span:nth-child(2)::before {
    top: 50%;
    transform: translateY(-50%) rotate(45deg); }
  .main_navigation_trigger.rotate span:nth-child(2)::after {
    bottom: 50%;
    transform: translateY(50%) rotate(-45deg); }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL NAVIGATION ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .social_navigation {
    display: none; } }

.social_navigation > ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; }
  .social_navigation > ul > li {
    list-style: none; }
    .social_navigation > ul > li:not(:first-child) {
      padding-left: 32px; }
    .social_navigation > ul > li > a {
      display: block;
      color: #F2F2F2;
      font-size: 14px;
      font-family: 'gilroy-medium', sans-serif;
      text-transform: uppercase; }
      .social_navigation > ul > li > a span + span {
        display: none !important; }
    @media screen and (min-width: 769px) {
      .social_navigation > ul > li:not(.active) > a:hover,
      .social_navigation > ul > li:not(.active) > a:focus {
        color: #EE5533; } }

.socialLink a svg path {
  transition: 0.3s; }

.socialLink a:hover svg path {
  fill: #EE5533; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SHARE NAVIGATION ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.share_navigation {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex: 1;
  padding-top: 96px; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .share_navigation {
      padding-top: 24px; } }
  @media screen and (max-width: 768px) {
    .share_navigation {
      display: none;
      padding: 32px 24px; } }
  .share_navigation .nav_heading {
    color: rgba(242, 242, 242, 0.48);
    font-size: 14px;
    font-family: 'gilroy-medium', sans-serif;
    text-transform: uppercase; }
  .share_navigation ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 12px;
    line-height: 1.4;
    list-style: none; }
    .share_navigation ul > li {
      padding-right: 12px;
      width: calc(33.33% - 12px); }
      .share_navigation ul > li > a {
        color: rgba(242, 242, 242, 0.48); }
        .share_navigation ul > li > a:after {
          content: "";
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          background: rgba(242, 242, 242, 0.48); }
        @media screen and (min-width: 769px) {
          .share_navigation ul > li > a:hover, .share_navigation ul > li > a:focus {
            color: #F2F2F2; }
            .share_navigation ul > li > a:hover:after, .share_navigation ul > li > a:focus:after {
              background: #F2F2F2; } }

#masks {
  position: absolute;
  top: -999em;
  left: -999em;
  z-index: 1; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero {
  width: 100%;
  height: 100vh;
  color: #F2F2F2;
  background: #14151A;
  overflow: hidden;
  /*
	.hero_bg_wrapper {
		@extend .full_size;
		overflow:hidden;

		.background {
			@extend .absolute;
			width:110%; height:100%;
			transform:translateX(-5%);

			img {
				display:block;
				width:100%; height:100%;
				object-fit:cover; font-family:'object-fit:cover;';
			}

			&.slice_01 {
				clip-path:url('#slice-01');
			}
			&.slice_02 {
				clip-path:url('#slice-02');
			}
			&.slice_03 {
				clip-path:url('#slice-03');
			}
			&.slice_04 {
				clip-path:url('#slice-04');
			}

			@media screen and (max-width:768px)
			{
				img {
					object-position:center center !important;
				}

				&.slice_02,
				&.slice_04 {
					opacity:.76 !important;
				}
				&.slice_03 {
					opacity:.88 !important;
				}
			}
		}
	}
	*/
  /*
	.hero_bg_wrapper {
		@extend .full_size;
		overflow:hidden;

		svg {

			.svgImage01 { clip-path:url(#svgPath01); }
			.svgImage02 { clip-path:url(#svgPath02); }
			.svgImage03 { clip-path:url(#svgPath03); }
			.svgImage04 { clip-path:url(#svgPath04); }

			image {
				object-fit:cover; font-family:'object-fit:cover;';
			}
		}
	}
	*/ }
  @media screen and (max-width: 768px) {
    .hero {
      z-index: 102; } }
  .hero.short {
    height: calc(75vh - 228px);
    min-height: 400px; }
    @media screen and (max-width: 768px) {
      .hero.short {
        padding-top: 72px;
        height: auto;
        min-height: 0; } }
    .hero.short .heading {
      top: calc(228px - 96px);
      bottom: 0;
      height: auto; }
      @media screen and (max-width: 768px) {
        .hero.short .heading {
          position: static;
          padding: 0 24px 32px; } }
      @media screen and (min-width: 769px) {
        .hero.short .heading h1, .hero.short .heading .heading_typo_1, .hero.short .hero_in .heading .center_content h2, .hero_in .hero.short .heading .center_content h2, .hero.short .hero_in .heading .center_content .heading_typo_2, .hero_in .hero.short .heading .center_content .heading_typo_2, .hero.short .hero_in .heading .center_content .list_type_3 .item .title, .hero_in .hero.short .heading .center_content .list_type_3 .item .title, .hero.short .list_type_3 .item .hero_in .heading .center_content .title, .list_type_3 .item .hero_in .hero.short .heading .center_content .title {
          text-align: center; } }
  .hero .cycle {
    overflow: hidden; }
    .hero .cycle .slide {
      width: 100%;
      height: 100%;
      /*
			&.cycle-slide-active {

				.heading h1 strong {
					opacity:1;

					&:before {
						left:0;
					}
					&:after {
						right:0;
					}
				}
			}
			*/ }
    .hero .cycle.cycle_hero_heading {
      z-index: 3; }
  .hero .hero_bg {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    width: calc(100% + 80px);
    height: auto;
    overflow: hidden;
    transform: translate(-50%, -50%); }
    .hero .hero_bg.portrait {
      width: auto;
      height: calc(100% + 80px); }
    .hero .hero_bg.hidden {
      opacity: 0; }
      .hero .hero_bg.hidden + picture {
        display: block; }
    .hero .hero_bg + picture {
      display: none; }
      .hero .hero_bg + picture img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit:cover;'; }
        @media screen and (max-width: 768px) {
          .hero .hero_bg + picture img {
            margin-top: -48px; } }
  .hero .heading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    padding: 0 96px;
    width: 100%;
    height: 100%;
    box-sizing: border-box; }
    @media screen and (max-width: 1024px) {
      .hero .heading {
        padding: 0 48px; } }
    .hero .heading .center_content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
    .hero .heading h1, .hero .heading .heading_typo_1, .hero .hero_in .heading .center_content h2, .hero_in .hero .heading .center_content h2, .hero .hero_in .heading .center_content .heading_typo_2, .hero_in .hero .heading .center_content .heading_typo_2, .hero .hero_in .heading .center_content .list_type_3 .item .title, .hero_in .hero .heading .center_content .list_type_3 .item .title, .hero .list_type_3 .item .hero_in .heading .center_content .title, .list_type_3 .item .hero_in .hero .heading .center_content .title {
      width: 100%;
      font-size: 24px;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .hero .heading h1, .hero .heading .heading_typo_1, .hero .hero_in .heading .center_content h2, .hero_in .hero .heading .center_content h2, .hero .hero_in .heading .center_content .heading_typo_2, .hero_in .hero .heading .center_content .heading_typo_2, .hero .hero_in .heading .center_content .list_type_3 .item .title, .hero_in .hero .heading .center_content .list_type_3 .item .title, .hero .list_type_3 .item .hero_in .heading .center_content .title, .list_type_3 .item .hero_in .hero .heading .center_content .title {
          display: flex;
          flex-direction: column-reverse; } }
      .hero .heading h1 strong, .hero .heading .heading_typo_1 strong, .hero .hero_in .heading .center_content h2 strong, .hero_in .hero .heading .center_content h2 strong, .hero .hero_in .heading .center_content .heading_typo_2 strong, .hero_in .hero .heading .center_content .heading_typo_2 strong, .hero .hero_in .heading .center_content .list_type_3 .item .title strong, .hero_in .hero .heading .center_content .list_type_3 .item .title strong, .hero .list_type_3 .item .hero_in .heading .center_content .title strong, .list_type_3 .item .hero_in .hero .heading .center_content .title strong {
        font-weight: 400;
        /*
				&:before {
					@extend .animate;
					content:attr(data-letters);
					position:absolute;
					top:0; left:-12px;
					z-index:2;
				}
				&:after {
					@extend .animate;
					content:attr(data-letters);
					position:absolute;
					top:0; right:-12px;
					z-index:2;
				}
				*/ }
      @media screen and (max-width: 768px) {
        .hero .heading h1 span, .hero .heading .heading_typo_1 span, .hero .hero_in .heading .center_content h2 span, .hero_in .hero .heading .center_content h2 span, .hero .hero_in .heading .center_content .heading_typo_2 span, .hero_in .hero .heading .center_content .heading_typo_2 span, .hero .hero_in .heading .center_content .list_type_3 .item .title span, .hero_in .hero .heading .center_content .list_type_3 .item .title span, .hero .list_type_3 .item .hero_in .heading .center_content .title span, .list_type_3 .item .hero_in .hero .heading .center_content .title span {
          padding-bottom: 12px; } }
      .hero .heading h1.small_gap span, .hero .heading .small_gap.heading_typo_1 span, .hero .hero_in .heading .center_content h2.small_gap span, .hero_in .hero .heading .center_content h2.small_gap span, .hero .hero_in .heading .center_content .small_gap.heading_typo_2 span, .hero_in .hero .heading .center_content .small_gap.heading_typo_2 span, .hero .hero_in .heading .center_content .list_type_3 .item .small_gap.title span, .hero_in .hero .heading .center_content .list_type_3 .item .small_gap.title span, .hero .list_type_3 .item .hero_in .heading .center_content .small_gap.title span, .list_type_3 .item .hero_in .hero .heading .center_content .small_gap.title span {
        line-height: 0.8; }
    .hero .heading a {
      margin-top: 48px; }
  .hero .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 4;
    padding: 96px; }
    @media screen and (max-width: 1024px) and (min-width: 769px) {
      .hero .bottom {
        padding: 48px; } }
    @media screen and (max-width: 768px) {
      .hero .bottom {
        position: fixed;
        padding: 0; }
        .hero .bottom .button.button_type_1 {
          width: 100%; } }
    .hero .bottom .center_content {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between; }
    .hero .bottom .scroll_down svg {
      fill: #F2F2F2; }
    @media screen and (min-width: 769px) {
      .hero .bottom .scroll_down:hover svg,
      .hero .bottom .scroll_down:focus svg {
        fill: #EE5533; } }
    @media screen and (max-width: 768px) {
      .hero .bottom .scroll_down {
        display: none; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IN-SECTION HERO -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero_in {
  width: 100%;
  max-height: 720px;
  color: #F2F2F2;
  background: #14151A;
  overflow: hidden; }
  .hero_in:before {
    content: "";
    display: block;
    padding-top: 37.50%;
    width: 100%;
    height: 0;
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      .hero_in:before {
        padding-top: 56.25%; } }
  .hero_in .heading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%; }
    .hero_in .heading .center_content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      padding: 0 96px;
      width: 100%;
      height: 100%;
      box-sizing: border-box; }
      @media screen and (max-width: 768px) {
        .hero_in .heading .center_content {
          padding: 0 48px; } }
      .hero_in .heading .center_content h2, .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .list_type_3 .item .title, .list_type_3 .item .hero_in .heading .center_content .title {
        text-align: center; }
        @media screen and (max-width: 768px) {
          .hero_in .heading .center_content h2, .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .list_type_3 .item .title, .list_type_3 .item .hero_in .heading .center_content .title {
            font-size: 24px; } }
  .hero_in .bg_wrapper {
    overflow: hidden;
    /*
		.background {
			@extend .absolute;
			width:110%; height:100%;
			transform:translateX(-5%);

			img {
				display:block;
				width:100%; height:100%;
				object-fit:cover; font-family:'object-fit:cover;';
			}

			&.slice_05 {
				clip-path:url('#slice-05');
			}
			&.slice_06 {
				clip-path:url('#slice-06');
			}
			&.slice_07 {
				clip-path:url('#slice-07');
			}

			@media screen and (max-width:768px)
			{
				img {
					object-position:center center !important;
				}

				&.slice_05,
				&.slice_06 {
					opacity:.88 !important;
				}
			}
		}
		*/ }
    .hero_in .bg_wrapper .hero_in_bg {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      width: calc(100% + 80px);
      height: auto;
      overflow: hidden;
      transform: translate(-50%, -50%); }
      .hero_in .bg_wrapper .hero_in_bg.portrait {
        width: auto;
        height: calc(100% + 80px); }
      .hero_in .bg_wrapper .hero_in_bg.hidden {
        opacity: 0; }
        .hero_in .bg_wrapper .hero_in_bg.hidden + .lazyload_wrapper {
          display: block; }
      .hero_in .bg_wrapper .hero_in_bg + .lazyload_wrapper {
        display: none; }
        .hero_in .bg_wrapper .hero_in_bg + .lazyload_wrapper img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit:cover;'; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list_type_1 {
  padding-top: 48px;
  border-bottom: 1px solid rgba(20, 21, 26, 0.12); }
  @media screen and (max-width: 768px) {
    .list_type_1 {
      padding-top: 24px; } }
  .list_type_1 .item {
    border-top: 1px solid rgba(20, 21, 26, 0.12); }
    @media screen and (min-width: 1025px) {
      .list_type_1 .item:nth-child(4n + 1) {
        border-left: none; } }
    @media screen and (max-width: 1024px) and (min-width: 769px) {
      .list_type_1 .item {
        width: 33.33%; }
        .list_type_1 .item:nth-child(3n + 1) {
          border-left: none; } }
    @media screen and (max-width: 768px) {
      .list_type_1 .item {
        width: 50%; }
        .list_type_1 .item:nth-child(2n + 1) {
          border-left: none; } }
    .list_type_1 .item:not(:first-child) {
      border-left: 1px solid rgba(20, 21, 26, 0.12); }
    .list_type_1 .item:before {
      content: "";
      display: block;
      padding-top: 56.25%;
      width: 100%;
      height: 0;
      overflow: hidden; }
    .list_type_1 .item .lazyload_wrapper {
      padding: 32px 48px;
      box-sizing: border-box; }
      @media screen and (max-width: 768px) {
        .list_type_1 .item .lazyload_wrapper {
          padding: 24px; } }
      .list_type_1 .item .lazyload_wrapper img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        font-family: 'object-fit:contain;';
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(grayScale=1)"; }
    .list_type_1 .item.hide {
      display: none !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 2) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list_type_2 {
  overflow: hidden; }
  .list_type_2 .item {
    width: 100%;
    color: #F2F2F2; }
    .list_type_2 .item .text {
      padding-left: 96px;
      line-height: 1.4;
      font-size: 32px;
      font-weight: 400; }
      @media screen and (max-width: 1024px) and (min-width: 769px) {
        .list_type_2 .item .text {
          padding-left: 48px; } }
      @media screen and (max-width: 768px) {
        .list_type_2 .item .text {
          padding-left: 0;
          font-size: 22px; } }
    .list_type_2 .item .info {
      padding-top: 48px; }
      @media screen and (max-width: 1024px) {
        .list_type_2 .item .info {
          padding-top: 24px; } }
      .list_type_2 .item .info .name,
      .list_type_2 .item .info .role {
        display: block; }
      .list_type_2 .item .info .name {
        font-size: 14px;
        font-family: 'gilroy-bold', sans-serif;
        text-transform: uppercase; }
      .list_type_2 .item .info .role {
        font-size: 16px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 3) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .list_type_3 .item {
    border-top: 1px solid rgba(20, 21, 26, 0.12); }
    .list_type_3 .item:not(:first-child) {
      margin-top: 24px; } }

.list_type_3 .item .date {
  font-size: 14px;
  font-family: 'gilroy-medium', sans-serif;
  text-transform: uppercase; }

.list_type_3 .item .title {
  padding-top: 3px;
  line-height: 1.3; }

.list_type_3 .item .category {
  padding-top: .5em; }
  .list_type_3 .item .category span {
    font-size: 14px;
    font-family: 'gilroy-medium', sans-serif;
    text-transform: uppercase; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 4) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list_type_4 {
  margin: 0 -24px; }
  .list_type_4.list_archive {
    margin-top: -48px; }
  .list_type_4 .cycle-carousel-wrap .slide {
    position: relative !important;
    z-index: 1;
    padding: 0 24px;
    width: calc(50% - 48px);
    height: 100%;
    opacity: 1 !important;
    vertical-align: top; }
    @media screen and (max-width: 768px) {
      .list_type_4 .cycle-carousel-wrap .slide {
        padding: 0 12px;
        width: calc(100% - 24px); } }
  .list_type_4 .item {
    position: relative !important;
    z-index: 1; }
    .list_type_4 .item .link {
      position: absolute;
      top: 0;
      left: 24px;
      right: 24px;
      bottom: 0;
      z-index: 2; }
    .list_type_4 .item .thumb {
      overflow: hidden; }
      .list_type_4 .item .thumb:before {
        content: "";
        display: block;
        padding-top: 56.25%;
        width: 100%;
        height: 0;
        overflow: hidden; }
      .list_type_4 .item .thumb:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        width: 18px;
        height: 18px;
        border: 2px solid rgba(242, 242, 242, 0.12);
        border-top: 2px solid #F2F2F2;
        border-radius: 50%;
        visibility: hidden;
        opacity: 0;
        transform: translate(-50%, -50%);
        animation: spin 1200ms linear infinite;
        transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out; }
      .list_type_4 .item .thumb .lazyload_wrapper img {
        display: block;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit:cover;';
        transform: translate(-50%, -50%); }
    .list_type_4 .item .text {
      flex: 1;
      padding: 32px;
      color: #F2F2F2;
      text-align: center;
      white-space: normal;
      background: rgba(242, 242, 242, 0.06); }
      .list_type_4 .item .text .date {
        color: rgba(242, 242, 242, 0.48);
        font-size: 14px;
        font-family: 'gilroy-medium', sans-serif;
        text-transform: uppercase; }
      .list_type_4 .item .text .title {
        margin: 0 auto;
        padding-top: .5em;
        width: 100%;
        max-width: 320px; }
    @media screen and (min-width: 769px) {
      .list_type_4 .item:not(.is-loading):hover .thumb .lazyload_wrapper img, .list_type_4 .item:not(.is-loading):focus .thumb .lazyload_wrapper img {
        width: 104%;
        height: 104%;
        opacity: .48; } }
    .list_type_4 .item.is-loading .thumb:after {
      visibility: visible;
      opacity: 1; }
    .list_type_4 .item.is-loading .thumb .lazyload_wrapper img {
      width: 104%;
      height: 104%;
      opacity: .24; }

.list_type_4_pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .list_type_4_pagination {
      padding-top: 24px; } }
  .list_type_4_pagination .pag_link {
    color: #F2F2F2; }
    @media screen and (min-width: 769px) {
      .list_type_4_pagination .pag_link:not(.disabled):hover, .list_type_4_pagination .pag_link:not(.disabled):focus {
        color: #EE5533; } }
    .list_type_4_pagination .pag_link + .pag_link {
      margin-left: 48px; }
    .list_type_4_pagination .pag_link.disabled {
      opacity: .32; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARTICLE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.ajax_article {
  position: fixed;
  z-index: 1;
  visibility: hidden;
  opacity: 0; }
  .ajax_article.open {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    visibility: visible;
    opacity: 1; }
    @media screen and (max-width: 768px) {
      .ajax_article.open {
        background: #14151A;
        overflow-y: auto; } }

.article_wrapper {
  z-index: 9999; }
  @media screen and (max-width: 768px) {
    .article_wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap; } }
  .article_wrapper:before {
    content: ""; }
    @media screen and (min-width: 769px) {
      .article_wrapper:before {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 2;
        width: 50%;
        background: #14151A; } }
  .article_wrapper .background {
    background: #14151A; }
    @media screen and (min-width: 769px) {
      .article_wrapper .background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1; } }
    @media screen and (max-width: 768px) {
      .article_wrapper .background {
        order: 1; } }
    .article_wrapper .background img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit:cover;'; }
  @media screen and (min-width: 769px) {
    .article_wrapper .article_meta,
    .article_wrapper .article_content {
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 3;
      width: 50%;
      overflow: hidden;
      overflow-y: auto; } }
  .article_wrapper .article_meta .column,
  .article_wrapper .article_content .column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    padding: 88px 96px;
    box-sizing: border-box; }
    @media screen and (max-width: 1024px) and (min-width: 769px) {
      .article_wrapper .article_meta .column,
      .article_wrapper .article_content .column {
        padding: 48px; } }
    @media screen and (max-width: 768px) {
      .article_wrapper .article_meta .column,
      .article_wrapper .article_content .column {
        padding: 32px 24px; } }
  @media screen and (min-width: 769px) {
    .article_wrapper .article_meta {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-end;
      left: 0; } }
  @media screen and (max-width: 768px) {
    .article_wrapper .article_meta {
      order: 2; } }
  .article_wrapper .article_meta .column {
    display: block;
    width: 100%;
    max-width: 706px;
    color: #F2F2F2;
    flex: none;
    min-height: 100%; }
    .article_wrapper .article_meta .column .height {
      position: relative;
      height: 100%; }
      @media screen and (min-width: 769px) {
        .article_wrapper .article_meta .column .height {
          box-sizing: border-box;
          padding-bottom: 152px; }
          .article_wrapper .article_meta .column .height .share_navigation {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0; } }
  .article_wrapper .article_content {
    right: 0; }
    @media screen and (max-width: 768px) {
      .article_wrapper .article_content {
        order: 3; } }
    .article_wrapper .article_content .column {
      background: #F2F2F2; }
      @media screen and (min-width: 769px) {
        .article_wrapper .article_content .column {
          min-height: 100vh;
          width: calc(100%); } }
      @media screen and (max-width: 1024px) and (min-width: 769px) {
        .article_wrapper .article_content .column {
          width: calc(100% - 48px); } }
    .article_wrapper .article_content .share_navigation {
      display: none; }
      @media screen and (max-width: 768px) {
        .article_wrapper .article_content .share_navigation {
          display: flex; } }
  @media screen and (max-width: 768px) {
    .article_wrapper .logo {
      display: none; } }
  .article_wrapper .date,
  .article_wrapper .category {
    line-height: 1.4;
    color: rgba(242, 242, 242, 0.48);
    font-size: 14px;
    font-family: 'gilroy-medium', sans-serif;
    text-transform: uppercase; }
    .article_wrapper .date a,
    .article_wrapper .date span,
    .article_wrapper .category a,
    .article_wrapper .category span {
      color: rgba(242, 242, 242, 0.48);
      font-size: 16px;
      text-transform: none; }
    .article_wrapper .date a:after,
    .article_wrapper .category a:after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: rgba(242, 242, 242, 0.48); }
    @media screen and (min-width: 769px) {
      .article_wrapper .date a:hover,
      .article_wrapper .date a:focus,
      .article_wrapper .category a:hover,
      .article_wrapper .category a:focus {
        color: #F2F2F2; }
        .article_wrapper .date a:hover:after,
        .article_wrapper .date a:focus:after,
        .article_wrapper .category a:hover:after,
        .article_wrapper .category a:focus:after {
          background: #F2F2F2; } }
  @media screen and (min-width: 769px) {
    .article_wrapper .date {
      padding-top: 96px; } }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .article_wrapper .date {
      padding-top: 48px; } }
  .article_wrapper .category {
    padding-top: 24px; }
    @media screen and (max-width: 768px) {
      .article_wrapper .category {
        padding-top: 18px; } }
  .article_wrapper .title {
    padding-top: 12px; }
    .article_wrapper .title {
      font-size: 24px; }
      @media screen and (min-width: 360px) {
        .article_wrapper .title {
          font-size: calc(24px + 40 * ((100vw - 360px) / 1240)); } }
      @media screen and (min-width: 1600px) {
        .article_wrapper .title {
          font-size: 64px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CASE STUDY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.case-study_intro {
  padding-top: 120px; }
  .case-study_intro .typography_inverted {
    color: #f2f2f2; }
  @media screen and (min-width: 768px) {
    .case-study_intro {
      padding-top: 196px; } }
  @media screen and (min-width: 1024px) {
    .case-study_intro {
      padding-top: 204px; } }
  .case-study_intro h1, .case-study_intro .heading_typo_1, .case-study_intro .hero_in .heading .center_content h2, .hero_in .heading .center_content .case-study_intro h2, .case-study_intro .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .case-study_intro .heading_typo_2, .case-study_intro .hero_in .heading .center_content .list_type_3 .item .title, .hero_in .heading .center_content .list_type_3 .item .case-study_intro .title, .case-study_intro .list_type_3 .item .hero_in .heading .center_content .title, .list_type_3 .item .hero_in .heading .center_content .case-study_intro .title {
    padding-top: 0;
    font-family: gilroy-semibold; }
    @media screen and (min-width: 768px) {
      .case-study_intro h1, .case-study_intro .heading_typo_1, .case-study_intro .hero_in .heading .center_content h2, .hero_in .heading .center_content .case-study_intro h2, .case-study_intro .hero_in .heading .center_content .heading_typo_2, .hero_in .heading .center_content .case-study_intro .heading_typo_2, .case-study_intro .hero_in .heading .center_content .list_type_3 .item .title, .hero_in .heading .center_content .list_type_3 .item .case-study_intro .title, .case-study_intro .list_type_3 .item .hero_in .heading .center_content .title, .list_type_3 .item .hero_in .heading .center_content .case-study_intro .title {
        font-size: 82px; } }

.case-study_figure img,
.case-study_hero img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 300ms; }

.case-study_hero {
  padding-top: 60%;
  background-color: #14151A; }

.case-study_info {
  margin-top: 40px;
  margin-bottom: 30px;
  max-width: 700px;
  color: #f2f2f2;
  font-size: 17px; }
  .case-study_info ul {
    padding-top: 0;
    margin-top: 0; }
  .case-study_info .column {
    margin-bottom: 18px; }
    @media screen and (min-width: 769px) {
      .case-study_info .column {
        margin-bottom: 40px;
        display: flex;
        flex-direction: row; } }

.case-study_info_heading {
  flex-shrink: 0;
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
  font-family: gilroy-bold; }
  @media screen and (min-width: 769px) {
    .case-study_info_heading {
      width: 95px; }
      .case-study_info_heading + span,
      .case-study_info_heading + ul {
        margin-top: -2px; } }

.case-study_info_list {
  color: #f2f2f2; }
  .case-study_info_list li {
    display: inline;
    padding: 0 !important; }
    .case-study_info_list li::before {
      display: none; }
    .case-study_info_list li:not(:last-child)::after {
      content: " /"; }

.case-study_content {
  font-size: 20px;
  color: #fff;
  font-family: gilroy-regular; }
  @media screen and (min-width: 768px) {
    .case-study_content {
      padding-bottom: 75px; } }

.case-study_figure-row {
  margin-top: 75px; }

.case-study_next_wrapper {
  margin-top: 170px;
  margin-bottom: 20px; }

.case-study_next {
  display: inline-block;
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
  font-family: gilroy-bold;
  margin-bottom: 30px; }

.case-study_next_title {
  font-family: gilroy-semibold; }
  .case-study_next_title,
  .case-study_next_title a {
    color: #fff; }
  @media screen and (min-width: 1024px) {
    .case-study_next_title {
      font-size: 40px; } }

.case-study_next_intro {
  color: #fff; }
  @media screen and (min-width: 769px) {
    .case-study_next_intro {
      padding-top: 19px; } }

.case-study_next_figure {
  display: block;
  margin-top: 65px;
  margin-bottom: 65px; }

.case-study_next_btn {
  color: #65666a;
  text-decoration: underline;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  transition: color 300ms; }
  .case-study_next_btn:hover {
    color: #fff; }
  .case-study_next_btn span {
    display: block;
    position: relative;
    border: 2px solid #36373d;
    border-radius: 100%;
    width: 51px;
    height: 51px;
    margin-left: 30px; }
  .case-study_next_btn svg {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: #36373d; }

/**
 * Slider
 */
.case-study_slider_wrap {
  background-color: #212227; }

.case-study_slider {
  background-color: #212227; }

.case-study_controls {
  display: flex;
  align-items: center;
  justify-content: center; }
  .case-study_controls .caption {
    padding: 0 32px;
    color: #f2f2f2; }

.case-study_slide {
  opacity: 1 !important; }
  .case-study_slide .content {
    color: #fff;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(20, 21, 26, 0.6), rgba(20, 21, 26, 0));
    padding: 30px;
    box-sizing: border-box;
    white-space: normal; }
    @media screen and (min-width: 768px) {
      .case-study_slide .content {
        padding: 45px 65px; } }
    .case-study_slide .content p:not(:first-child) {
      padding-top: 9px; }
  @media screen and (min-width: 768px) {
    .case-study_slide {
      width: 50%; } }
  @media screen and (min-width: 1024px) {
    .case-study_slide {
      width: 33.3333%; } }

.case-study_slide_link {
  position: relative;
  display: block; }
  .case-study_slide_link::before {
    content: "";
    padding-bottom: 125%;
    display: block; }
  .case-study_slide_link .lazyload_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SLICK ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGOS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logos_wrapper {
  position: relative; }
  .logos_wrapper.bleed_96 {
    padding-top: 0;
    padding-left: 0 !important;
    padding-right: 0 !important; }
  @media screen and (max-width: 768px) {
    .logos_wrapper {
      padding-top: 24px !important;
      padding-bottom: 24px !important; }
      .logos_wrapper.bleed_96 {
        padding-left: 96px !important;
        padding-right: 96px !important; } }

.logos {
  margin-left: -15px;
  margin-right: -15px; }
  .logos::before, .logos::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    width: 70px;
    z-index: 1; }
    @media screen and (min-width: 768px) {
      .logos::before, .logos::after {
        width: 120px; } }
  .logos::before {
    left: 0;
    background-image: linear-gradient(to right, #F2F2F2, rgba(242, 242, 242, 0)); }
    .bg_theme_01-04 .logos::before {
      background-image: linear-gradient(to right, #e4e4e4, rgba(228, 228, 228, 0)); }
  .logos::after {
    right: 0;
    background-image: linear-gradient(to left, #F2F2F2, rgba(242, 242, 242, 0)); }
    .bg_theme_01-04 .logos::after {
      background-image: linear-gradient(to left, #e4e4e4, rgba(228, 228, 228, 0)); }
  .logos .slick-track {
    display: flex;
    align-items: center; }
  .logos .slick-slide {
    margin-left: 15px;
    margin-right: 15px; }
  .logos .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #202327;
    width: 48px;
    height: 48px;
    border: 2px solid;
    border-radius: 100%;
    background-color: transparent;
    opacity: 0.5;
    transition: opacity 300ms;
    cursor: pointer; }
    .logos .slick-arrow:hover, .logos .slick-arrow:focus {
      opacity: 0.75; }
    .logos .slick-arrow svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 14px;
      fill: currentColor; }
  .logos .slick-prev {
    right: calc(100% + 12px); }
    .logos .slick-prev svg {
      transform: translate(-50%, -50%) rotate(0.5turn); }
  .logos .slick-next {
    left: calc(100% + 12px); }

/*------------------------------------*\
    $HERO
\*------------------------------------*/
.illustrated-map {
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 799px) {
    .illustrated-map {
      display: none; } }

.illustrated-map-portable {
  margin-bottom: 30px; }
  @media screen and (min-width: 800px) {
    .illustrated-map-portable {
      display: none; } }

.illustrated-map-item {
  position: absolute; }

.illustrated-map-truck {
  width: 4.3%;
  left: -4.3%;
  top: 49.8%;
  animation: hero-truck linear 12s infinite; }

.illustrated-map-tunnel-cover {
  width: 10.4%;
  left: 38.5%;
  top: 71.1%; }

.illustrated-map-tunnel-cover-top {
  width: 9.6%;
  left: 6.9%;
  top: 57.9%; }

@keyframes hero-truck {
  0% {
    left: -4.3%;
    top: 49.8%; }
  15%, 59.99999999% {
    left: 8%;
    top: 60.3%; }
  60% {
    left: 41.9%;
    top: 71.5%; }
  100% {
    top: 100%;
    left: 76%; } }

.illustrated-map-birds {
  width: 9%;
  top: 70%;
  left: -9%;
  animation: hero-birds linear 32s infinite;
  animation-delay: 2s; }

@keyframes hero-birds {
  0% {
    left: -9%;
    top: 70%; }
  100% {
    top: -10%;
    left: 95%; } }

.illustrated-map-birds polygon:nth-of-type(even), .illustrated-map-birds path:nth-of-type(even) {
  animation: hero-birds-inner 1s ease-in-out infinite; }

@keyframes hero-birds-inner {
  0%, 100% {
    transform: none; }
  50% {
    transform: translateY(5%); } }

.illustrated-map-drone-left {
  left: 58.3%;
  top: 26%;
  animation: hero-drone-left 6s ease-in-out infinite; }

@keyframes hero-drone-left {
  0%, 100% {
    transform: none; }
  50% {
    transform: translateY(-50%); } }

.illustrated-map-drone-right {
  left: 72.3%;
  top: 32.2%;
  animation: hero-drone-right 6s ease-in-out infinite; }

@keyframes hero-drone-right {
  0%, 100% {
    transform: none; }
  50% {
    transform: translateY(50%); } }

.illustrated-map-turbine {
  left: 13.5%;
  top: 11.8%;
  width: 8.9%; }

.illustrated-map-turbine-manned {
  left: 34.5%;
  top: 20.8%;
  width: 7.3%; }

.illustrated-map-turbine-2 {
  left: 45.6%;
  top: 30%;
  width: 8.9%; }

.illustrated-map-turbine-base {
  left: 49.4%;
  top: 47%;
  width: 3.3%; }

.illustrated-map-turbine-3 {
  left: 78.6%;
  top: 31%;
  width: 8.9%; }

.illustrated-map-turbine-4 {
  left: 84.6%;
  top: 24.8%;
  width: 8.9%; }

.illustrated-map-crane-load {
  width: 2.5%;
  left: 55.6%;
  top: 18.9%; }

.illustrated-map-crane-load-2 {
  width: 2.5%;
  left: 75.9%;
  top: 27.9%; }

.illustrated-map-wide-door {
  width: 3.54%;
  left: 55.15%;
  top: 75.2%;
  animation: wide-door 7s ease-in-out infinite; }

.illustrated-map-wide-door-cover {
  width: 3.74%;
  left: 55.05%;
  top: 73.6%; }

@keyframes wide-door {
  0%, 100% {
    transform: none; }
  50% {
    transform: translateY(-35%); } }

.illustrated-map-door {
  width: 1.5%;
  left: 23.2%;
  top: 52.5%;
  animation: door 7s ease-in-out infinite; }

.illustrated-map-door-cover {
  width: 1.5%;
  left: 23.2%;
  top: 51.1%; }

.illustrated-map-door-2 {
  width: 1.5%;
  left: 68%;
  top: 65.2%;
  animation: door 7s ease-in-out infinite;
  animation-delay: 3.5s; }

.illustrated-map-door-2-cover {
  width: 1.5%;
  left: 68%;
  top: 63.8%; }

@keyframes door {
  0%, 100% {
    transform: none; }
  50% {
    transform: translateY(-50%); } }

.illustrated-map-person {
  width: 0.2%; }

.illustrated-map-person-1 {
  left: 38.6%;
  top: 34.9%; }

.illustrated-map-person-2 {
  animation: person-2 10s linear infinite; }

.illustrated-map-person-3 {
  left: 49.5%;
  top: 19%; }

.illustrated-map-person-4 {
  left: 51.7%;
  top: 21.3%; }

.illustrated-map-person-5 {
  left: 56.2%;
  top: 14%; }

.illustrated-map-person-6 {
  animation: person-6 10s linear infinite; }

.illustrated-map-person-7 {
  left: 62.3%;
  top: 13.7%; }

.illustrated-map-person-8 {
  animation: person-8 40s linear infinite; }

.illustrated-map-person-9 {
  left: 61.8%;
  top: 30.3%; }

.illustrated-map-person-10 {
  left: 65.7%;
  top: 31.7%; }

.illustrated-map-person-11 {
  left: 66.3%;
  top: 29%; }

.illustrated-map-person-12 {
  left: 67.5%;
  top: 28.4%; }

.illustrated-map-person-13 {
  left: 67.5%;
  top: 29.6%; }

.illustrated-map-person-14 {
  left: 67.7%;
  top: 30.6%; }

.illustrated-map-person-15 {
  left: 69.1%;
  top: 34.7%; }

@keyframes person-2 {
  0%, 100% {
    left: 49.6%;
    top: 20.5%; }
  50% {
    left: 50%;
    top: 20%; } }

@keyframes person-6 {
  0%, 100% {
    left: 56.5%;
    top: 14.9%; }
  50% {
    left: 56.8%;
    top: 14.6%; } }

@keyframes person-8 {
  0%, 100% {
    left: 59.9%;
    top: 31.6%; }
  50% {
    left: 61.9%;
    top: 28.9%; } }

.illustrated-map-person-climbing,
.illustrated-map-person-climbing-2,
.illustrated-map-person-climbing-3 {
  width: 0.4%; }

.illustrated-map-person-climbing {
  left: 38.4%;
  top: 37.3%;
  animation: climb 28s linear infinite; }

@keyframes climb {
  0%, 100% {
    left: 38.4%;
    top: 37.3%; }
  50% {
    left: 38.6%;
    top: 35.8%; } }

.illustrated-map-person-climbing-2 {
  left: 59.4%;
  top: 34.8%;
  animation: climb-2 25s linear infinite; }

@keyframes climb-2 {
  0%, 100% {
    left: 59.4%;
    top: 34.8%; }
  50% {
    left: 59.5%;
    top: 33.8%; } }

.illustrated-map-person-climbing-3 {
  left: 60.7%;
  top: 35%;
  transform: scaleX(-1); }

.illustrated-map-painting {
  width: 1%;
  height: 2%;
  left: 60.3%;
  top: 34.3%;
  background-color: #e95044;
  transform-origin: top left;
  animation: painting 15s linear infinite; }

@keyframes painting {
  0% {
    transform: scaleX(0); }
  95% {
    transform: none;
    opacity: 1; }
  100% {
    opacity: 0; } }

.illustrated-map-painting-cover {
  width: 1%;
  left: 60.3%;
  top: 34%; }

.illustrated-map-smoke {
  width: 1%;
  opacity: 0; }
  .illustrated-map-smoke::before {
    content: "";
    display: block;
    background-image: radial-gradient(rgba(255, 243, 218, 0.61) 0%, rgba(255, 243, 218, 0) 85%);
    padding-top: 100%;
    animation: sway 3s ease-in-out infinite; }

@keyframes sway {
  0%, 100% {
    transform: none; }
  50% {
    transform: translateX(-50%); } }

.illustrated-map-smoke-1,
.illustrated-map-smoke-3 {
  left: 46.3%;
  top: 15%;
  animation: smoke-1 5s linear infinite; }

.illustrated-map-smoke-3 {
  animation-delay: 3s; }

@keyframes smoke-1 {
  0%, 100% {
    transform: none;
    opacity: 0;
    left: 46.3%;
    top: 15%; }
  10% {
    opacity: 1;
    left: 46.3%;
    top: 15%; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  90% {
    transform: scale(2);
    left: 46.3%;
    top: 10%; } }

.illustrated-map-smoke-2,
.illustrated-map-smoke-4 {
  left: 46.3%;
  top: 15%;
  animation: smoke-2 5s linear infinite;
  animation-delay: 2s; }

.illustrated-map-smoke-4 {
  animation-delay: 4s; }

@keyframes smoke-2 {
  0%, 100% {
    transform: none;
    opacity: 0;
    left: 46.3%;
    top: 15%; }
  10% {
    opacity: 1;
    left: 46.3%;
    top: 15%; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  90% {
    transform: scale(3);
    left: 50.3%;
    top: 10%; } }

.illustrated-map-smoke-3,
.illustrated-map-smoke-5 {
  left: 47.3%;
  top: 16%;
  animation: smoke-3 5s linear infinite;
  animation-delay: 4s; }

.illustrated-map-smoke-5 {
  animation-delay: 5s; }

@keyframes smoke-3 {
  0%, 100% {
    transform: none;
    opacity: 0;
    left: 47.3%;
    top: 16%; }
  10% {
    opacity: 1;
    left: 47.3%;
    top: 16%; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  90% {
    transform: scale(3);
    left: 50.3%;
    top: 8%; } }

/**
 * Mobile Hero stuff
 */
.illustrated-map-figure {
  width: 100%; }

.illustrated-map-section {
  padding: rem(38px) rem(25px) rem(42px)-30px;
  margin-bottom: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: rem(3px);
  text-align: center; }

.illustrated-map-figure-wrapper {
  position: relative;
  margin-bottom: 30px; }

.illustrated-map-figure-electrical .illustrated-map-door {
  width: 3.7%;
  left: 39.3%;
  top: 53.7%; }

.illustrated-map-figure-electrical .illustrated-map-door-cover {
  width: 3.7%;
  left: 39.3%;
  top: 49%; }

.illustrated-map-figure-fabrication .illustrated-map-wide-door {
  left: 49.3%;
  width: 9.54%;
  top: 33.4%; }

.illustrated-map-figure-fabrication .illustrated-map-wide-door-cover {
  left: 49.3%;
  width: 9.54%;
  top: 28.3%; }

.illustrated-map-figure-turbine .illustrated-map-figure {
  max-height: rem(250px); }

.illustrated-map-figure-turbine .illustrated-map-turbine-base {
  top: 86%;
  width: rem(50px);
  left: 47.5%; }

.illustrated-map-figure-ship .illustrated-map-crane-load {
  left: 59%;
  top: 47.5%;
  width: 12%; }

.illustrated-map-figure-ship .illustrated-map-smoke {
  width: 5%; }
  .illustrated-map-figure-ship .illustrated-map-smoke::before {
    content: "";
    display: block;
    background-image: radial-gradient(rgba(255, 243, 218, 0.61) 0%, rgba(255, 243, 218, 0) 85%);
    padding-top: 100%;
    animation: sway 3s ease-in-out infinite; }

@keyframes sway {
  0%, 100% {
    transform: none; }
  50% {
    transform: translateX(-50%); } }

.illustrated-map-figure-ship .illustrated-map-smoke-1,
.illustrated-map-figure-ship .illustrated-map-smoke-3 {
  left: 17.3%;
  top: 30%;
  animation: smoke-1-mobile 5s linear infinite; }

.illustrated-map-figure-ship .illustrated-map-smoke-3 {
  animation-delay: 3s; }

@keyframes smoke-1-mobile {
  0%, 100% {
    transform: none;
    opacity: 0;
    left: 17.3%;
    top: 30%; }
  10% {
    opacity: 1;
    left: 17.3%;
    top: 30%; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  90% {
    transform: scale(2);
    left: 17.3%;
    top: 10%; } }

.illustrated-map-figure-ship .illustrated-map-smoke-2,
.illustrated-map-figure-ship .illustrated-map-smoke-4 {
  left: 23.3%;
  top: 33%;
  animation: smoke-2-mobile 5s linear infinite;
  animation-delay: 2s; }

.illustrated-map-figure-ship .illustrated-map-smoke-4 {
  animation-delay: 4s; }

@keyframes smoke-2-mobile {
  0%, 100% {
    transform: none;
    opacity: 0;
    left: 23.3%;
    top: 33%; }
  10% {
    opacity: 1;
    left: 23.3%;
    top: 33%; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  90% {
    transform: scale(3);
    left: 26.3%;
    top: 10%; } }

.illustrated-map-figure-ship .illustrated-map-smoke-3,
.illustrated-map-figure-ship .illustrated-map-smoke-5 {
  left: 17.3%;
  top: 30%;
  animation: smoke-3-mobile 5s linear infinite;
  animation-delay: 4s; }

.illustrated-map-figure-ship .illustrated-map-smoke-5 {
  animation-delay: 5s; }

@keyframes smoke-3-mobile {
  0%, 100% {
    transform: none;
    opacity: 0;
    left: 17.3%;
    top: 30%; }
  10% {
    opacity: 1;
    left: 17.3%;
    top: 30%; }
  50% {
    opacity: 1; }
  75% {
    opacity: 0; }
  90% {
    transform: scale(3);
    left: 25.3%;
    top: 8%; } }

.illustrated-map-figure-rig .illustrated-map-drone-left {
  left: 1.3%;
  top: 32.2%;
  width: 20%; }

.illustrated-map-figure-rig .illustrated-map-drone-right {
  left: 72.3%;
  top: 62.2%;
  width: 20%; }

.illustrated-map-figure-rig .illustrated-map-crane-load {
  width: 12%;
  left: 84.6%;
  top: 40.6%; }

/* LAYOUT */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* BODY ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
body.single-post {
  overflow: hidden !important; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
/* CENTER CONTENT --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.center_content {
  margin: 0 auto; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main_container {
  flex: 1; }

.testimonials_wrapper .controls,
.latest_news_wrapper .controls {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .testimonials_wrapper .controls,
    .latest_news_wrapper .controls {
      justify-content: space-between; } }
  .testimonials_wrapper .controls .caption,
  .latest_news_wrapper .controls .caption {
    padding: 0 32px;
    color: #F2F2F2; }
  .testimonials_wrapper .controls.hide,
  .latest_news_wrapper .controls.hide {
    display: none; }

.testimonials_wrapper .controls {
  background: #14151A; }
  @media screen and (min-width: 769px) {
    .testimonials_wrapper .controls {
      position: absolute;
      right: -24px;
      bottom: -37px;
      z-index: 101;
      padding: 24px; } }
  @media screen and (max-width: 768px) {
    .testimonials_wrapper .controls {
      padding-top: 24px; } }

.latest_news_wrapper .controls {
  justify-content: center;
  padding-top: 48px; }
  @media screen and (max-width: 768px) {
    .latest_news_wrapper .controls {
      padding-top: 24px; } }

.services_wrapper ul > li {
  padding-left: 35px;
  color: rgba(20, 21, 26, 0.48); }
  .services_wrapper ul > li:before {
    top: calc(.5em + 11px);
    left: 0;
    width: 11px;
    height: 11px;
    background: none;
    border: 2px solid #EE5533;
    box-sizing: border-box; }

.careers_wrapper .center_content {
  position: relative;
  z-index: 3; }

.careers_wrapper .background {
  overflow: hidden; }
  .careers_wrapper .background img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit:cover;'; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wBase {
  max-width: 1220px; }

.wMedium {
  max-width: 980px; }

.wSmall {
  max-width: 720px; }

.wXSmall {
  max-width: 440px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BLEEDS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.bleed_48 {
  padding: 48px;
  width: calc(100% - 96px); }

.bleed_96 {
  padding: 96px;
  width: calc(100% - 192px); }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .bleed_96 {
      padding: 48px;
      width: calc(100% - 96px); } }
  @media screen and (max-height: 768px) and (min-width: 769px) and (orientation: landscape) {
    .bleed_96 {
      padding: 48px 96px; }
      .box_type_1 .bleed_96 {
        padding-left: 0;
        padding-right: 0; } }

@media screen and (max-width: 768px) {
  .bleed_48,
  .bleed_96 {
    padding: 24px;
    width: auto; } }

.bleed_top_12 {
  padding-top: 12px; }

.bleed_top_24 {
  padding-top: 24px; }

.bleed_top_48 {
  padding-top: 48px; }

.bleed_top_96 {
  padding-top: 96px; }

@media screen and (max-width: 768px) {
  .bleed_top_12 {
    padding-top: 6px; } }

@media screen and (max-width: 768px) {
  .bleed_top_24 {
    padding-top: 12px; } }

@media screen and (max-width: 768px) {
  .bleed_top_48,
  .bleed_top_96 {
    padding-top: 24px; } }

.bleed_bottom_48 {
  padding-bottom: 48px; }

@media screen and (max-width: 768px) {
  .bleed_bottom_48 {
    padding-bottom: 24px; } }

.bleed_bottom_24 {
  padding-bottom: 24px; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BACKGROUNDS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.bg_theme_01-01 {
  background: #14151A; }

.bg_theme_01-02 {
  background: #EE5533; }

.bg_theme_01-03 {
  background: #F2F2F2; }

.bg_theme_01-04 {
  background: #e4e4e4; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 96px; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .header {
      padding: 48px; } }
  @media screen and (max-width: 768px) {
    .header {
      padding: 24px; } }
  .header.alert-banner-visible {
    top: 80px; }
    .header.alert-banner-visible.open {
      top: 0 !important; }
    @media screen and (max-width: 768px) {
      .header.alert-banner-visible.is-sticky {
        top: 0 !important; } }
  .header:before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #262524;
    opacity: 0; }
  .header .center_content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .header .center_content {
        justify-content: space-between; } }
  .header.is-sticky:before {
    opacity: 1; }
  @media screen and (min-width: 769px) {
    .header.is-sticky {
      padding: 32px; } }
  .header.is-sticky .center_content {
    max-width: 100%; }
  @media screen and (max-width: 768px) {
    .header .center_content::before {
      content: '';
      display: block;
      position: absolute;
      top: -24px;
      left: 8px;
      right: -24px;
      background-color: rgba(0, 0, 0, 0);
      height: 78px;
      z-index: 10;
      visibility: hidden;
      transition: visibility 300ms ease-in-out, background-color 300ms ease-in-out; }
    .header.open .center_content::before {
      background-color: black; }
    .header.open .logo {
      margin-left: 30px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo {
  display: block;
  width: auto;
  height: 36px; }
  @media screen and (max-width: 768px) {
    .logo {
      position: relative;
      z-index: 99;
      height: 24px;
      transition: margin 0.3s; } }
  .logo svg {
    display: block;
    width: auto;
    height: 36px;
    fill: #F2F2F2; }
    .logo svg #trademark-t,
    .logo svg #trademark-m {
      display: none !important; }
    @media screen and (max-width: 768px) {
      .logo svg {
        height: 24px; } }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer {
  padding: 96px 96px 48px;
  background: #EE5533; }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .footer {
      padding: 48px; } }
  @media screen and (max-width: 768px) {
    .footer {
      padding: 24px 24px 85px; } }
  .footer .center_content {
    padding-top: 96px; }
    @media screen and (max-width: 1024px) and (min-width: 769px) {
      .footer .center_content {
        padding-top: 48px; } }
    @media screen and (max-width: 768px) {
      .footer .center_content {
        padding-top: 8px; } }
    .footer .center_content > .grid > .column {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-end; }
      @media screen and (max-width: 768px) {
        .footer .center_content > .grid > .column.footer_column_01, .footer .center_content > .grid > .column.footer_column_02, .footer .center_content > .grid > .column.footer_column_03, .footer .center_content > .grid > .column.footer_column_05 {
          padding-top: 24px;
          border-top: 1px solid rgba(20, 21, 26, 0.12); }
        .footer .center_content > .grid > .column.footer_column_02, .footer .center_content > .grid > .column.footer_column_03, .footer .center_content > .grid > .column.footer_column_05 {
          margin-top: 24px; }
        .footer .center_content > .grid > .column.footer_column_04 {
          display: none; }
        .footer .center_content > .grid > .column.footer_column_06 {
          padding: 12px 0 8px; } }
    .footer .center_content .footer_column_03 ul {
      display: flex; }
      .footer .center_content .footer_column_03 ul li {
        width: auto;
        padding-right: 30px; }
        .footer .center_content .footer_column_03 ul li a::after {
          display: none; }
        .footer .center_content .footer_column_03 ul li a svg path {
          fill: black; }
        .footer .center_content .footer_column_03 ul li a:hover svg path {
          fill: white; }
  .footer h2, .footer .heading_typo_2, .footer .list_type_3 .item .title, .list_type_3 .item .footer .title {
    margin-bottom: 0; }
  .footer p:not(:first-child) {
    padding-top: 1em; }
  .footer ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none; }
    .footer ul > li {
      padding-right: 12px;
      width: calc(50% - 12px); }
      @media screen and (max-width: 768px) {
        .footer ul > li {
          width: calc(33.33% - 12px); } }
      .footer ul > li a span:first-child {
        display: none !important; }
  .footer .link {
    font-family: 'gilroy-bold', sans-serif; }
    .footer .link:after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: #14151A; }
    @media screen and (min-width: 769px) {
      .footer .link:hover:after, .footer .link:focus:after {
        opacity: 0; } }
    .footer .link.footer_link {
      font-size: 14px;
      text-transform: uppercase; }
      .footer .link.footer_link + .footer_link {
        margin-left: 24px; }
      @media screen and (max-width: 768px) {
        .footer .link.footer_link {
          display: inline-block;
          margin-left: 0 !important;
          margin-right: 24px; } }
  .footer .email_link {
    font-family: 'gilroy-bold', sans-serif;
    margin-bottom: 16px;
    border-bottom: 1px solid #14151A; }
    @media screen and (min-width: 769px) {
      .footer .email_link:hover, .footer .email_link:focus {
        border-bottom: 1px solid transparent; } }
  .footer .scroll_up svg {
    fill: #14151A; }

.footer_copyright {
  font-size: 13px; }
