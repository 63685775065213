
    body {
        transition-property: margin;
        transition-duration:300ms;
        transition-timing-function:ease-in-out;
    }
    .alert-margin {
        @media screen and (max-width:768px)
        {
            margin-top: 0 !important
        }
    }


.alert_banner_con {
    @extend .animate;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $color_theme_01-02;
    min-height: 80px;
    z-index: 999;
    overflow: hidden;
    padding: 20px 96px;
    box-sizing: border-box;
    &.is-sticky {
		@media screen and (min-width:769px)
		{
			padding: 20px 32px;
            
		}
        @media screen and (max-width:768px)
        {
            transform: translateY(-100%);
        }
		.alert_banner_inner {
			max-width:100%;
		}
	}
    @media screen and (max-width:1024px) and (min-width:769px)
	{
		padding: 20px 48px;
	}
	@media screen and (max-width:768px)
	{
		padding: 20px 24px;
	}
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 50%;
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
        background-color: lighten($color: $color_theme_01-02, $amount: 5%);
        z-index: 0;
        @media screen and (max-width:768px)
        {
            clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
        }
    }
    .alert_banner_inner {
        @extend .animate;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        position: relative;
        @media screen and (max-width:768px)
        {
			flex-wrap: wrap;
        }
        .alert_banner_inner__textarea {
            padding-right: 20px;
            p {
                color: $color_theme_01-03;
            }
            .title {
                font-family: gilroy-extrabold,sans-serif;
                font-size: 20px;
                @media screen and (max-width:768px)
                {
                    margin-bottom: 10px;
                    font-size: 18px;
                }
            }
            .body {
                font-size: 18px;
                @media screen and (max-width:768px)
                {
                    margin-bottom: 10px;
                    font-size: 16px;
                }
            }
        }
        .button {
            background-color: $color_theme_01-03;
            padding-top: 15px;
            padding-bottom: 15px;
            color: $color_theme_01-02;
            min-width: 190px;
            box-sizing: border-box;
            @media screen and (max-width:768px)
            {
                width: 100%;
            }
            svg {
                fill: $color_theme_01-02;
            }
        }
    }
} 