/* ------------------------------------------------------------------------------------------------------------------------ */
/* SOCIAL NAVIGATION ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.social_navigation {

	@media screen and (max-width:768px)
	{
		display:none;
	}

	& > ul {
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;

		& > li {
			list-style:none;

			&:not(:first-child) {
				padding-left:32px;
			}

			& > a {
				@extend .animate;
				@extend .relative;
				display:block;
				color:$color_theme_01-03;
				font-size:$font_size_small;
				font-family:'gilroy-medium', sans-serif;
				text-transform:uppercase;

				span + span {
					display:none !important;
				}
			}

			@media screen and (min-width:769px)
			{
				&:not(.active) > a:hover,
				&:not(.active) > a:focus {
					color:$color_theme_01-02;
				}
			}
		}
	}
}
.socialLink {
	a {
		svg {
			path {
				transition: 0.3s;
			}
		}
		&:hover {
			svg {
				path {
					fill: $color_theme_01-02;
				}
			}
		}

	}
}