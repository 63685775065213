/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography {
	font-size:22px;
	color:$color_theme_01-01;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		font-size:18px;
	}
	@media screen and (max-width:768px)
	{
		font-size:$font_size_base;
	}

	h1, h2, h3, h4, h5, h6, p, ul, ol {
		padding-top:1.5em;

		@media screen and (max-width:768px)
		{
			padding-top:1em;
		}

		&:first-child {
			padding-top:0;
		}
	}

	h1, h2, h3, h4, h5, h6, strong {
		color:$color_theme_01-01;
	}

	.pre {
		@extend .heading_type_1;
		padding-bottom:1em;
	}

	ul {
		margin-top:-.5em;
		list-style:none;

		& > li {
			@extend .relative;
			padding-top:.5em; padding-left:48px;
			line-height:$line_height_small;

			@media screen and (max-width:768px)
			{
				padding-left:20px;
			}

			&:before {
				@extend .absolute;
				content:"";
				top:calc(.5em + 13px); left:24px;
				width:9px; height:9px;
				border-radius:100%;
				background:$color_theme_01-01;

				@media screen and (max-width:768px)
				{
					top:calc(.5em + 10px); left:0;
					width:6px; height:6px;
				}
			}
		}
	}

	p a:not(.button) {
		@extend .animate;
		@extend .relative;

		&:after {
			@extend .animate;
			@extend .absolute;
			content:"";
			left:0; right:0; bottom:0;
			height:1px;
			background:$color_theme_01-01;
		}

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:$color_theme_01-02;

				&:after {
					background:$color_theme_01-02;
				}
			}
		}
	}

	p img {
		max-width:100%;
	}

	small {
		font-size:$font_size_base;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY INVERTED ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_inverted {
	color:rgba($color_theme_01-03, .48);

	h1, h2, h3, h4, h5, h6, strong {
		color:$color_theme_01-03;
	}

	.pre {
		margin-bottom:0;
		font-family:'gilroy-bold', sans-serif;
	}

	ul > li:before {
		background:$color_theme_01-03;
	}

	p a:not(.button) {
		color:rgba($color_theme_01-03, .48);

		&:after {
			background:rgba($color_theme_01-03, .48);
		}

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:$color_theme_01-03;

				&:after {
					background:$color_theme_01-03;
				}
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* TYPOGRAPHY INTRO ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.typography_intro {

	@media screen and (max-width:768px)
	{
		text-align:center;
	}
}
