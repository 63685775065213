/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list_type_1 {
	padding-top:48px;
	border-bottom:1px solid rgba($color_theme_01-01, .12);

	@media screen and (max-width:768px)
	{
		padding-top:24px;
	}

	.item {
		@extend .relative;
		border-top:1px solid rgba($color_theme_01-01, .12);

		@media screen and (min-width:1025px)
		{
			&:nth-child(4n + 1) {
				border-left:none;
			}
		}
		@media screen and (max-width:1024px) and (min-width:769px)
		{
			width:33.33%;

			&:nth-child(3n + 1) {
				border-left:none;
			}
		}
		@media screen and (max-width:768px)
		{
			width:50%;

			&:nth-child(2n + 1) {
				border-left:none;
			}
		}

		&:not(:first-child) {
			border-left:1px solid rgba($color_theme_01-01, .12);
		}

		&:before {
			content:"";
			@extend .relative;
			display:block;
			padding-top:56.25%;
			width:100%; height:0;
			overflow:hidden;
		}

		.lazyload_wrapper {
			@extend .full_size;
			padding:32px 48px;
			box-sizing:border-box;

			@media screen and (max-width:768px)
			{
				padding:24px;
			}

			img {
				display:block;
				width:100%; height:100%;
				object-fit:contain; font-family:'object-fit:contain;';
				filter:grayscale(100%); -webkit-filter:grayscale(100%); -ms-filter:"progid:DXImageTransform.Microsoft.BasicImage(grayScale=1)";
			}
		}

		&.hide {
			display:none !important;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 2) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list_type_2 {
	overflow:hidden;

	.item {
		width:100%;
		color:$color_theme_01-03;

		.text {
			padding-left:96px;
			line-height:1.4;
			font-size:32px;
			font-weight:400;

			@media screen and (max-width:1024px) and (min-width:769px)
			{
				padding-left:48px;
			}
			@media screen and (max-width:768px)
			{
				padding-left:0;
				font-size:22px;
			}
		}

		.info {
			padding-top:48px;

			@media screen and (max-width:1024px)
			{
				padding-top:24px;
			}

			.name,
			.role {
				display:block;
			}
			.name {
				font-size:$font_size_small;
				font-family:'gilroy-bold', sans-serif;
				text-transform:uppercase;
			}
			.role {
				font-size:$font_size_base;
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 3) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list_type_3 {

	.item {

		@media screen and (max-width:768px)
		{
			border-top:1px solid rgba($color_theme_01-01, .12);

			&:not(:first-child) {
				margin-top:24px;
			}
		}

		.date {
			font-size:$font_size_small;
			font-family:'gilroy-medium', sans-serif;
			text-transform:uppercase;
		}
		.title {
			padding-top:3px;
			@extend .heading_typo_2;
			line-height:1.3;
		}
		.category {
			padding-top:.5em;

			span {
				font-size:$font_size_small;
				font-family:'gilroy-medium', sans-serif;
				text-transform:uppercase;
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LIST (TYPE 4) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.list_type_4 {
	margin:0 -24px;

	&.list_archive {
		margin-top:-48px;
	}

	.cycle-carousel-wrap {
		// display:flex !important;
		// flex-direction:row;
		// flex-wrap:nowrap;

		.slide {
			position:relative !important;
			z-index:1;
			padding:0 24px;
			width:calc(50% - 48px); height:100%;
			opacity:1 !important;
			vertical-align:top;

			@media screen and (max-width:768px)
			{
				padding:0 12px;
				width:calc(100% - 24px);
			}
		}
	}

	.item {
		// display:flex !important;
		// flex-direction:column;
		// flex-wrap:nowrap;
		position:relative !important;
		z-index:1;

		.link {
			position:absolute;
			top:0; left:24px; right:24px; bottom:0;
			z-index:2;
		}

		.thumb {
			@extend .relative;
			overflow:hidden;

			&:before {
				@extend .relative;
				content:"";
				display:block;
				padding-top:56.25%;
				width:100%; height:0;
				overflow:hidden;
			}

			&:after {
				content:"";
				position:absolute;
				top:50%; left:50%;
				z-index:3;
				width:18px; height:18px;
				border:2px solid rgba($color_theme_01-03, .12); border-top:2px solid $color_theme_01-03;
				border-radius:50%;
				visibility:hidden;
				opacity:0;
				transform:translate(-50%, -50%);
				animation:spin 1200ms linear infinite;
				transition:visibility 300ms ease-in-out, opacity 300ms ease-in-out;
			}

			.lazyload_wrapper {
				@extend .full_size;

				img {
					@extend .animate;
					display:block;
					top:50%; left:50%;
					width:100%; height:100%;
					object-fit:cover; font-family:'object-fit:cover;';
					transform:translate(-50%, -50%);
				}
			}
		}

		.text {
			@extend .relative;
			flex:1;
			padding:32px;
			color:$color_theme_01-03;
			text-align:center;
			white-space:normal;
			background:rgba($color_theme_01-03, .06);

			.date {
				color:rgba($color_theme_01-03, .48);
				font-size:$font_size_small;
				font-family:'gilroy-medium', sans-serif;
				text-transform:uppercase;
			}
			.title {
				margin:0 auto; padding-top:.5em;
				width:100%; max-width:$site_width_xxsmall;
			}
		}

		@media screen and (min-width:769px)
		{
			&:not(.is-loading):hover,
			&:not(.is-loading):focus {

				.thumb .lazyload_wrapper img {
					width:104%; height:104%;
					opacity:.48;
				}
			}
		}

		&.is-loading {

			.thumb:after {
				visibility:visible;
				opacity:1;
			}
			.thumb .lazyload_wrapper img {
				width:104%; height:104%;
				opacity:.24;
			}
		}
	}
}

.list_type_4_pagination {
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	justify-content:center;
	padding-top:48px;

	@media screen and (max-width:768px)
	{
		padding-top:24px;
	}

	.pag_link {
		@extend .animate;
		color:$color_theme_01-03;

		@media screen and (min-width:769px)
		{
			&:not(.disabled):hover,
			&:not(.disabled):focus {
				color:$color_theme_01-02;
			}
		}

		& + .pag_link {
			margin-left:48px;
		}

		&.disabled {
			opacity:.32;
		}
	}
}
