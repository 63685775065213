/* ------------------------------------------------------------------------------------------------------------------------ */
/* FORM (TYPE 1) ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.form_type_1 {
	padding-top:48px;

	@media screen and (max-width:768px)
	{
		padding-top:24px;
	}

	/* --- Field --- */
	.field-wrapper_hidden {
		@extend .animate;
		max-height: 0;
		overflow: hidden;
	}
		.field {
			@extend .relative;

			label {
				font-size:22px;

				@media screen and (max-width:768px)
				{
					font-size:$font_size_base;
				}

				&.field_label {
					padding:12px 0 6px;
				}

				&.in_field_label {
					padding:24px 32px;
					color:$color_theme_01-02;

					@media screen and (max-width:768px)
					{
						padding:14px 18px;
					}
				}
			}
			label {

				a {
					@extend .animate;
					color:$color_theme_01-01;
					text-decoration:underline;

					@media screen and (min-width:769px)
					{
						&:hover,
						&:focus {
							text-decoration:none;
						}
					}
				}
			}
		}

	/* --- Input text --- */
	input[type=tel],
	input[type=text],
	input[type=email],
	input[type=password],
	textarea {
		@extend .animate;
		padding:24px 32px;
		color:$color_theme_01-02;
		font-size:22px;
		box-sizing:border-box;
		background:$color_theme_01-01;

		@media screen and (max-width:768px)
		{
			padding:14px 18px;
			font-size:$font_size_base;
		}

		&:not([value=""]) + .in_field_label,
		&:focus + .in_field_label {
			padding:12px 32px;
			font-size:9px;

			@media screen and (max-width:768px)
			{
				padding:7px 18px;
				font-size:8px;
			}
		}
	}

	/* --- Select --- */
	.field_select {

		select {
			@extend .animate;
			@extend .relative;
			padding:24px 32px 24px 66px;
			color:$color_theme_01-02;
			font-size:22px;
			background:$color_theme_01-01;
			box-sizing:border-box;

			@media screen and (max-width:768px)
			{
				padding:14px 18px 14px 41px;
				font-size:$font_size_base;
			}

			option {
				color:$color_theme_01-01;
				font-size:$font_size_base;
				background:$color_theme_01-03;
			}

			&:hover + .arrow {
				transform:translateY(-50%) rotate(-90deg);
			}
		}

		.arrow {
			@extend .animate;
			position:absolute;
			top:50%; left:32px;
			z-index:2;
			width:18px; height:18px;
			fill:$color_theme_01-02;
			transform:translateY(-50%) rotate(90deg);

			@media screen and (max-width:768px)
			{
				left:18px;
				width:14px; height:14px;
			}
		}
	}

	/* --- Input checkbox & radio --- */
	.input_radio,
	.input_checkbox {
		margin-top:24px;

		input[type=radio],
		input[type=checkbox] {
			width:32px; height:32px;

			@media screen and (min-width:769px)
			{
				&:not(:focus):hover + span {
					border:1px solid rgba($color_theme_01-02, .24);
				}
			}

			&:focus + span {
				border:1px solid $color_theme_01-01;
			}
		}
		span {
			margin-right:14px;
			width:32px; height:32px;
			background:#FFF;
			border:1px solid rgba($color_theme_01-02, .12);

			svg {
				padding:9px;
				width:12px; height:12px;
				fill:$color_theme_01-02;
			}
		}
	}

	::placeholder {
		color:$color_theme_01-02;
	}

	/* --- Feedback --- */
	.field_feedback {
		color:$color_theme_01-01;
		background:rgba($color_theme_01-01, .12);
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* GOOGLE RECAPTCHA ------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.g-recaptcha {
	display:none;
}
