/* ------------------------------------------------------------------------------------------------------------------------ */
/* DOCUMENT --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
* {
	margin:0; padding:0;
	font-size:100%;
	vertical-align:baseline;
	outline:none;
}

html {
	text-size-adjust:100%;
	background-color:$color_theme_01-03;
}

body {
	margin:0; padding:0;
	line-height:$line_height_base;
	color:$color_theme_01-01;
	font-family:$font_family_01;
	font-size:$font_size_base;
	font-weight:400;
	cursor:default;
	overflow-x:auto; overflow-y:scroll;

	@media screen and (max-width:768px)
	{
		line-height:$line_height_small;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SECTIONS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
	display:block;
}

audio, canvas, video {
	display:inline-block;
	*display:inline;
	*zoom:1;
}
audio:not([controls]) { display:none; }

/* ------------------------------------------------------------------------------------------------------------------------ */
/* SELECTIONS ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
::selection {
	color:#FFF;
	background-color:$color_theme_01-02;
}
::-moz-selection {
	color:#FFF;
	background-color:$color_theme_01-02;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* KEYFRAMES -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

@keyframes spin
{
	0%   { transform: rotate(0deg);   }
	50%  { transform: rotate(180deg); }
	100% { transform: rotate(360deg); }
}

@keyframes highlight
{
	0%   { transform:translate(-50%, -50%) scale(1);   }
	50%  { transform:translate(-50%, -50%) scale(1.1); }
	100% { transform:translate(-50%, -50%) scale(1);   }
}
