/* ------------------------------------------------------------------------------------------------------------------------ */
/* FOOTER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.footer {
	padding:96px 96px 48px;
	background:$color_theme_01-02;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px 24px 85px;
	}

	.center_content {
		padding-top:96px;

		@media screen and (max-width:1024px) and (min-width:769px)
		{
			padding-top:48px;
		}
		@media screen and (max-width:768px)
		{
			padding-top:8px;
		}

		& > .grid > .column {
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			justify-content:flex-end;

			@media screen and (max-width:768px)
			{
				&.footer_column_01,
				&.footer_column_02,
				&.footer_column_03,
				&.footer_column_05 {
					padding-top:24px;
					border-top:1px solid rgba($color_theme_01-01, .12);
				}
				&.footer_column_02,
				&.footer_column_03,
				&.footer_column_05 {
					margin-top:24px;
				}
				&.footer_column_04 {
					display:none;
				}
				&.footer_column_06 {
					padding:12px 0 8px;
				}
			}
		}

		.footer_column_03 {
			ul {
				display: flex;
				li {
					width: auto;
					padding-right: 30px;
					a {
						&::after {
							display: none;
						}
						svg {
							path {
								fill: black;
							}
						}
						&:hover {
							svg {
								path {
									fill: white;
								}
							}
						}
					}
				}
			}
		}
	}

	h2 {
		@extend .heading_type_1;
		margin-bottom:0;
	}

	p:not(:first-child) {
		padding-top:1em;
	}

	ul {
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		list-style:none;

		& > li {
			padding-right:12px;
			width:calc(50% - 12px);

			@media screen and (max-width:768px)
			{
				width:calc(33.33% - 12px);
			}

			a span:first-child {
				display:none !important;
			}
		}
	}

	.link {
		@extend .relative;
		font-family:'gilroy-bold', sans-serif;

		&:after {
			@extend .animate;
			@extend .absolute;
			content:"";
			left:0; right:0; bottom:0;
			height:1px;
			background:$color_theme_01-01;
		}

		@media screen and (min-width:769px)
		{
			&:hover:after,
			&:focus:after {
				opacity:0;
			}
		}

		&.footer_link {
			font-size:$font_size_small;
			text-transform:uppercase;

			& + .footer_link {
				margin-left:24px;
			}

			@media screen and (max-width:768px) {
				display: inline-block;
				margin-left:0 !important;
				margin-right:24px;
			}
		}
	}

	.email_link {
		@extend .animate;
		@extend .relative;
		font-family:'gilroy-bold', sans-serif;
		margin-bottom: 16px;
		border-bottom: 1px solid $color_theme_01-01;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				border-bottom: 1px solid transparent;
			}
		}
	}

	.scroll_up svg {
		fill:$color_theme_01-01;
	}
}

.footer_copyright {
	font-size:13px;
}