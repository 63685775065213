/* ------------------------------------------------------------------------------------------------------------------------ */
/* COOKIE NOTIFICATION ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.cookie_notification {
	display:none;
	position:fixed;
	left:24px; bottom:81px;
	z-index:103;
	padding:32px;
	width:100%; max-width:200px;
	line-height:$line_height_small;
	color:$color_theme_01-01;
	font-size:$font_size_small;
	text-align:center;
	background:#FFF;

	@media screen and (min-width:769px)
	{
		bottom:24px;
	}

	p > a {
		@extend .relative;
		color:$color_theme_01-01;

		&:after {
			@extend .animate;
			@extend .absolute;
			content:"";
			left:0; right:0; bottom:0;
			height:1px;
			background:$color_theme_01-01;
		}

		@media screen and (min-width:769px)
		{
			&:hover:after,
			&:focus:after {
				opacity:0;
			}
		}
	}

	.cookie_close {
		@extend .animate;

		&.anchor {
			display:inline-block;
			margin:12px auto 0;
			color:$color_theme_01-01;
			font-family:'gilroy-bold', sans-serif;
			text-decoration:none;

			@media screen and (min-width:769px)
			{
				&:hover,
				&:focus {
					color:$color_theme_01-02;
				}
			}
		}

		&.icon {
			@extend .absolute;
			top:0; right:0;
			padding:12px;
			width:12px; height:12px;

			svg {
				@extend .animate;
				display:block;
				width:12px; height:12px;
				fill:$color_theme_01-01;
			}

			@media screen and (min-width:769px)
			{
				&:hover svg,
				&:focus svg {
					fill:$color_theme_01-02;
				}
			}
		}
	}
}
