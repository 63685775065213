/* ------------------------------------------------------------------------------------------------------------------------ */
/* HEADER ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.header {
	@extend .animate;
	position:fixed;
	top:0; left:0; right:0;
	z-index:999;
	padding:96px;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px;
	}
	@media screen and (max-width:768px)
	{
		padding:24px;
	}

	&.alert-banner-visible {
		top: 80px;
		&.open {
			top: 0 !important;
		}
		@media screen and (max-width:768px)
        {
			&.is-sticky {
				top: 0 !important;
			}
        }
	}

	&:before {
		@extend .animate;
		@extend .absolute;
		content:"";
		top:0; left:0;
		width:100%; height:100%;
		background:$color_theme_01-05;

		opacity:0;
	}

	.center_content {
		@extend .animate;
		position:relative;
		z-index:2;
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		align-items:center;

		@media screen and (max-width:768px)
		{
			justify-content:space-between;
		}
	}

	&.is-sticky {


		&:before {
			opacity:1;
		}
		@media screen and (min-width:769px)
		{
			padding:32px;

		}

		.center_content {
			max-width:100%;
		}
	}

	@media screen and (max-width:768px)
	{
		.center_content {
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: -24px;
				left: 8px;
				right: -24px;
				background-color: rgba($color: #000000, $alpha: 0);
				height: 78px;
				z-index: 10;
				visibility:hidden;
				transition: visibility 300ms ease-in-out, background-color 300ms ease-in-out;
			}
		}
		&.open {
			.center_content {
				&::before {
					background-color: rgba($color: #000000, $alpha: 1);
				}
			}
			.logo {
				margin-left: 30px;
			}
		}
	}

}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.logo {
	display:block;
	width:auto; height:36px;

	@media screen and (max-width:768px)
	{
		position:relative;
		z-index: 99; 
		height: 24px;
		transition: margin 0.3s;
	}

	svg {
		display:block;
		width:auto; height:36px;
		fill:$color_theme_01-03;

		#trademark-t,
		#trademark-m {
			display:none !important;
		}

		@media screen and (max-width:768px)
		{
			height:24px;
		}
	}
}
