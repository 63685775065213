/* ------------------------------------------------------------------------------------------------------------------------ */
/* CASE STUDY ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

.case-study_intro {
    padding-top:120px;

    .typography_inverted {
        color:#f2f2f2;
    }

    @media screen and (min-width:768px) {
        padding-top:196px;
    }

    @media screen and (min-width:1024px) {
        padding-top:204px;
    }

    h1 {
        padding-top:0;
        font-family:gilroy-semibold;

        @media screen and (min-width:768px) {
            font-size:82px;
        }
    }
}

.case-study_figure,
.case-study_hero {
    img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        transition:opacity 300ms;
    }
}

.case-study_hero {
    padding-top:60%;
    background-color:$color_theme_01-01;
}

.case-study_info {
    margin-top:40px;
    margin-bottom:30px;
    max-width:700px;
    color:#f2f2f2;
    font-size:17px;

    ul {
        padding-top:0;
        margin-top:0;
    }

    .column {
        margin-bottom:18px;

        @media screen and (min-width:769px)
	    {
            margin-bottom:40px;
            display:flex;
            flex-direction:row;
        }
    }
}

.case-study_info_heading {
    flex-shrink:0;
    font-size:15px;
    text-transform:uppercase;
    color:#fff;
    font-family:gilroy-bold;

    @media screen and (min-width:769px)
	{
        width:95px;

        & + span,
        & + ul {
            margin-top:-2px;
        }
    }
}
.case-study_info_list {
    color:#f2f2f2;

    li {
        display:inline;
        padding:0 !important;

        &::before {
            display:none;
        }

        &:not(:last-child) {
            &::after {
                content:" /";
            }
        }
    }
}

.case-study_content {
    font-size:20px;
    color:#fff;
    font-family:gilroy-regular;

    @media screen and (min-width:768px) {
        padding-bottom:75px;
    }
}

.case-study_figure-row {
    margin-top:75px;
}

.case-study_next_wrapper {
    margin-top:170px;
    margin-bottom:20px;
}

.case-study_next {
    display:inline-block;
    font-size: 15px;
    text-transform:uppercase;
    color:#fff;
    font-family:gilroy-bold;
    margin-bottom:30px;
}

.case-study_next_title {
    font-family:gilroy-semibold;

    &,
    a {
        color:#fff;
    }

    @media screen and (min-width:1024px) {
        font-size:40px;
    }
}
.case-study_next_intro {
    color:#fff;

    @media screen and (min-width:769px)
	{
        padding-top:19px;
    }
}

.case-study_next_figure {
    display:block;
    margin-top:65px;
    margin-bottom:65px;
}

.case-study_next_btn {
    color:#65666a;
    text-decoration:underline;
    display:inline-flex;
    align-items:center;
    font-size:14px;
    transition:color 300ms;

    &:hover {
        color:#fff;
    }

    span {
        display:block;
        position:relative;
        border:2px solid #36373d;
        border-radius:100%;
        width:51px;
        height:51px;
        margin-left:30px;
    }

    svg {
        position:absolute;
        width:15px;
        height:15px;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        fill: #36373d;
    }
}


/**
 * Slider
 */

.case-study_slider_wrap {
    background-color:#212227;
}

.case-study_slider {
    background-color:#212227;

    .cycle-carousel-wrap {
        // display:flex !important;
    }
}

.case-study_controls {
    display:flex;
    align-items:center;
    justify-content:center;

    .caption {
        padding:0 32px;
        color:#f2f2f2;
    }
}

.case-study_slide {
    opacity:1 !important;

    .content {
        color:#fff;
        position:absolute;
        z-index:1;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-image:linear-gradient(to bottom, rgba($color_theme_01-01, 0.6), rgba($color_theme_01-01, 0));
        padding:30px;
        box-sizing:border-box;
        white-space:normal;

        @media screen and (min-width:768px) {
            padding:45px 65px;
        }

        p:not(:first-child) {
            padding-top:9px;
        }
    }

    @media screen and (min-width:768px) {
        width:50%;
    }

    @media screen and (min-width:1024px) {
        width:33.3333%;
    }
}

.case-study_slide_link {
    position:relative;
    display:block;

    &::before {
        content:"";
        padding-bottom:125%;
        display:block;
    }

    .lazyload_wrapper {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
}
