/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN NAVIGATION -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main_navigation {
	flex:1;
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	justify-content:flex-end;
	.main_navigation-inner {
		& > ul {
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			align-items:center;
			& > li {
				list-style:none;
	
				&:not(:first-child) {
					padding-left:32px;
				}
	
				& > a {
					@extend .animate;
					@extend .relative;
					color:$color_theme_01-03;
	
					&:after {
						@extend .animate;
						@extend .absolute;
					}
					&:after {
						content:"";
						bottom:-2px; left:0; right:0;
						height:0;
						background:$color_theme_01-02;
					}
	
					span {
						position:relative;
						z-index:2;
					}
				}
	
				&.active > a {
					color:$color_theme_01-02;
					cursor:default;
				}
	
				&:not(.active) > a:hover:after,
					&:not(.active) > a:focus:after {
						height:6px;
					}
	
				&.cta {
	
					& > a {
	
						&:after {
							content:"";
							height:6px;
						}
					}
	
					&:not(.active) > a:hover:after,
					&:not(.active) > a:focus:after,
					&.active > a:after {
						height:0;
					}
				}
			}
	
			& + ul {
				display:none;
			}
		}
		.mobBgAniCon {
			display: none;
		}
	}
}


@media screen and (max-width:768px) {
	// Mobile nav
	.mobile_nav {
		flex-direction: column;
		position: fixed;
		top:0; left:0; right:0; bottom:0;
		z-index:9;
		background-color: rgba($color: #000000, $alpha: 0);
		visibility:hidden;
		transition: visibility 300ms ease-in-out, background-color 300ms ease-in-out;
		// Lin element
		.mobLineEle {
			display: none;
			position: absolute;
			top: -100%;
			height: 100%;
			left: 30px;
			width: 2px;
			background-color: $color_theme_01-02;
			animation-name: lineExpandDown;
			animation-duration: 0.6s;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;
			z-index: 15;
			@keyframes lineExpandDown {
				0% {top: -100%;}
				100% {top: 0;}
			}
		}
		.main_navigation-inner {
			max-height: 100vh;
			position: absolute;
			top: 78px;
			left: 30px;
			right: 0;
			bottom: 0;
			overflow-y: auto;
			// ul
			.navLinksUl {
				width: 100%;
				overflow-x: hidden;
				display: none;
				flex-direction: column;
				box-sizing: border-box;
				justify-content: space-between;
				padding: 80px 0 80px 30px;
				height: calc(100% - 60px);
				position: relative;
				z-index: 1;
				@media(max-height: 750px) {
					padding: 50px 0 50px 30px;
				}
				@media(max-height: 600px) {
					padding: 20px 0 20px 30px;
				}
				li {
					width: 100%;
					text-align: left;
					padding-left: 0 !important;
					font-size: 22px;
					transform: translateX(40px); 
					opacity: 0;
					transition: ease-in-out 0.3s;
					padding: 10px 0;
					a {
						&:after {
							content:"";
							height: 0 !important;
						}
					}
					&.show {
						transform: translateX(0); 
						opacity: 1;
					}
				}
			}
			// Socials
			.socialsUl {
				display: none;
				transition: visibility 300ms ease-in-out, background-color 300ms ease-in-out;
				overflow-y: hidden;
				position: relative;
				height: 60px;
				padding-left: 30px;
				z-index: 1;
				li {
					transform: translateY(200%);
					$animationDelay: 0.2s;
					animation: slideUp 0.3s ease-in-out;
					animation-fill-mode: forwards;
					a {
						&::after {
							content:"";
							height: 0 !important;
						}
					}
					@keyframes slideUp {
						0% {transform: translateY(200%);}
						100% {transform: translateY(0);}
					}
					&:nth-child(1) {animation-delay: $animationDelay * 1;}
					&:nth-child(2) {animation-delay: $animationDelay * 2;}
					&:nth-child(3) {animation-delay: $animationDelay * 3;}
					&:nth-child(4) {animation-delay: $animationDelay * 4;}
					&:nth-child(5) {animation-delay: $animationDelay * 5;}
					&:nth-child(6) {animation-delay: $animationDelay * 6;}
					&:nth-child(7) {animation-delay: $animationDelay * 7;}
					&:nth-child(8) {animation-delay: $animationDelay * 8;}
					&:nth-child(9) {animation-delay: $animationDelay * 9;}
					&:nth-child(10) {animation-delay: $animationDelay * 10;}
				}
			}

			// Bg background
			.mobBgAniCon {
				position: absolute;
				top: 0;
				left: 2px;
				right: 0;
				bottom: 0;
				z-index: 0;
				overflow: hidden;
				display: none;
				opacity: 0;
				transition: 0.3s;
				transition-delay: 1s;
				$blockAniDuration: 0.4s;
				.blockOne {
					display: block;
					position: absolute;
					top: 40%;
					left: -20%;
					width: 140%;
					background-color: #090909;
					height: 150px;
					transform: rotate(15deg);
					z-index: 3;

					animation: blockOneAni $blockAniDuration;
					animation-fill-mode: forwards;
					animation-delay: 1s;
					@keyframes blockOneAni {
						0% {
							height: 130px;
						}
						25% {
							height: 150px;
						}
						75% {
							height: 130px;
						}
						100% {
							height: 150px;
						}
					}
				} 	
				.blockTwo {
					display: block;
					position: absolute;
					top: calc(40% + 140px);
					left: -20%;
					width: 140%;
					background-color: #161616;
					height: 0;
		
					transform: rotate(12deg);
					z-index: 2;
					visibility: hidden;

					animation: blockTwoAni $blockAniDuration + 0.1s;
					animation-fill-mode: forwards;
					animation-delay: 1.35s;
					@keyframes blockTwoAni {
						0% {
							height: 0;
							visibility: visible;
						}
						25% {
							height: 140px;
							visibility: visible;
						}
						50% {
							height: 160px;
							visibility: visible;
						}
						75% {
							height: 140px;
							visibility: visible;
						}
						100% {
							height: 160px;
							visibility: visible;
						}
					}
				} 
				.blockThree {
					display: block;
					position: absolute;
					top: calc(40% + 280px);
					left: -20%;
					width: 140%;
					background-color: #1B191A;
					height: 0;
		
					transform: rotate(9deg);
					z-index: 1;
					visibility: hidden;

					animation: blockThreeAni $blockAniDuration ease-out;
					animation-fill-mode: forwards;
					animation-delay: 1.8s;
					@keyframes blockThreeAni {
						0% {
							height: 0;
							visibility: visible;
						}
						100% {
							height: calc(100% - 40% - 150px);
							visibility: visible;
						}
					}
				}
				.gritLogoIcon {
					width: 150px;
					position: absolute;
					right: -75px;
					top: 50%;
					opacity: 0;
					transform: translateY(-50%) scale(1.4);
					z-index: 4;
					visibility: hidden;
					animation: navMobLogoAn $blockAniDuration ease-out;
					animation-fill-mode: forwards;
					animation-delay: 2.1s;
					@keyframes navMobLogoAn {
						0% {
							visibility: visible;
							transform: translateY(-50%) scale(1.4);
							opacity: 0;
						}
						100% {
							visibility: visible;
							transform: translateY(-50%) scale(1);
							opacity: 1;
						}
					}
				}
			}

		}  


		//  OPEN
		&.open {
			visibility:visible;
			background-color: rgba($color: #000000, $alpha: 1.0);
			.mobLineEle {
				display: block;
			}
			.main_navigation-inner {
				.navLinksUl {
					display: flex;
				}
				.socialsUl {
					display: flex;
				}
				// Bg background
				.mobBgAniCon {
					display: flex;
					opacity: 1;
				}
			}

		}
	}
}


/* --------------------------------------------------------------------------------------------------- */
/* MAIN NAVIGATION TRIGGER --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------- */

.main_navigation_trigger {
	@extend .animate;
	display:none;
	position: relative;
	@media screen and (max-width:768px) {
		display: block;
		width: 40px;
		height: 30px;
	}
	span {
		display: block;
		position: absolute;
		z-index: 99;
		top: 0;
		left: 0;
		width: 40px;
		height: 30px;
		// Center
		&:nth-child(1) {
			&::before {
				content:"";
				position: absolute;
				display: block;
				width: 100%; 
				height: 5px;
				top: 50%;
				transform: translateY(-50%);
				transition: 0.3s ease-in-out;
				left: 0;
				background:$color_theme_01-03;
			}
		}
		// Top bot
		&:nth-child(2) {

			&::before,
			&::after {
				content:"";
				left:0;
				display: block;
				position: absolute;
				width: 60%; 
				height: 5px;
				transition: 0.1s ease-in-out;
				background:$color_theme_01-03;
				animation-fill-mode: forwards;
				transform: translateY(0) rotate(0);
			}
			&::before {
				top: 0;
				left: 0;
			}
			&::after {
				bottom: 0;
				left: auto;
				right: 0;
			}
		}
	}
	&.open {
		span {


			// Top bot
			&:nth-child(2) {
	
				&::before,
				&::after {
					width: 100%; 
				}

			}
		}
	}
	&.merge {
		span {
						// Center
						&:nth-child(1) {
							&::before {
								opacity: 0;
							}
						}
			// Top bot
			&:nth-child(2) {
				&::before {
					top: 50%;
					transform: translateY(-50%) rotate(0);
				}
				&::after {
					bottom: 50%;
					transform: translateY(50%) rotate(0);
				}
			}
		}
	}
	&.rotate {
		span {
			// Top bot
			&:nth-child(2) {
	
				&::before,
				&::after {
					width: 100%; 
				}
				&::before {
					top: 50%;
					transform: translateY(-50%) rotate(45deg);
				}
				&::after {
					bottom: 50%;
					transform: translateY(50%) rotate(-45deg);
				}
			}
		}
	}
}