/* ------------------------------------------------------------------------------------------------------------------------ */
/* THEME COLORS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- PRIMARY --- */
$color_theme_01-01 : #14151A;  // Balck(ish)
$color_theme_01-02 : #EE5533;  // Red
$color_theme_01-03 : #F2F2F2;  // White(ish)
$color_theme_01-04 : #BA3314;  // Dark red
$color_theme_01-05 : #262524;  // Dark gray

/* --- SYSTEM COLORS --- */
$color_error  : $color_theme_01-02;
$color_alert  : #F89406;
$color_valid  : #00B16A;
$color_update : #1E8BC3;

/* --- SOCIAL COLORS --- */
$color_facebook  : #3B5998;
$color_twitter   : #4099FF;
$color_linkedin  : #0077B5;
$color_instagram : #125688;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT FAMILIES ---------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@font-face {
	font-family:'gilroy-extrabold';
	src:url('../../assets/fonts/gilroy/gilroy-extrabold.woff2') format('woff2'),
		url('../../assets/fonts/gilroy/gilroy-extrabold.woff') format('woff');
	font-weight:normal;
	font-style:normal;
}
@font-face {
	font-family:'gilroy-bold';
	src:url('../../assets/fonts/gilroy/gilroy-bold.woff2') format('woff2'),
		url('../../assets/fonts/gilroy/gilroy-bold.woff') format('woff');
	font-weight:normal;
	font-style:normal;
}
@font-face {
	font-family:'gilroy-semibold';
	src:url('../../assets/fonts/gilroy/gilroy-semibold.woff2') format('woff2'),
		url('../../assets/fonts/gilroy/gilroy-semibold.woff') format('woff');
	font-weight:normal;
	font-style:normal;
}
@font-face {
	font-family:'gilroy-medium';
	src:url('../../assets/fonts/gilroy/gilroy-medium.woff2') format('woff2'),
		url('../../assets/fonts/gilroy/gilroy-medium.woff') format('woff');
	font-weight:normal;
	font-style:normal;
}
@font-face {
	font-family:'gilroy-regular';
	src:url('../../assets/fonts/gilroy/gilroy-regular.woff2') format('woff2'),
		url('../../assets/fonts/gilroy/gilroy-regular.woff') format('woff');
	font-weight:normal;
	font-style:normal;
}

$font_family_01 : 'gilroy-regular', sans-serif;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FONT SIZES ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$font_size_small : 14px;
$font_size_base  : 16px;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LINE HEIGHTS ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$line_height_small : 1.4;
$line_height_base  : 1.6;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* LAYOUT ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
$site_width_base    : 1220px;
$site_width_medium  : 980px;
$site_width_small   : 720px;
$site_width_xsmall  : 440px;
$site_width_xxsmall : 320px;

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FLUID TEXT ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}
