/* ------------------------------------------------------------------------------------------------------------------------ */
/* GRID SYSTEM ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.grid {
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;

	@media screen and (max-width:768px)
	{
		margin-left:0 !important; margin-right:0 !important;
	}

	/* Widths */
	.width_01-12 { width:8.33%; }
	.width_02-12 { width:16.66%; }
	.width_03-12 { width:25%; }
	.width_04-12 { width:33.33%; }
	.width_05-12 { width:41.66%; }
	.width_06-12 { width:50%; }
	.width_07-12 { width:58.33%; }
	.width_08-12 { width:66.66%; }
	.width_09-12 { width:75%; }
	.width_10-12 { width:83.33%; }
	.width_11-12 { width:91.66%; }
	.width_12-12 { width:100%; }
	.width_01-05 { width:20%; }
	.width_02-05 { width:40%; }
	.width_03-05 { width:60%; }
	.width_04-05 { width:80%; }
	.width_01-07 { width:14.28%; }
	.width_01-08 { width:12.5%; }

	/* Column */
	.column {
		flex-direction:column;
		box-sizing:border-box;

		@media screen and (max-width:768px)
		{
			padding-left:0 !important; padding-right:0 !important;
			width:100%;
		}
	}

	/* Gutter 12px */
	&.gutter_12 {
		margin-left:-6px; margin-right:-6px;

		& > .column { padding-left:6px; padding-right:6px; }
	}

	/* Gutter 24px */
	&.gutter_24 {
		margin-left:-12px; margin-right:-12px;

		& > .column { padding-left:12px; padding-right:12px; }
	}

	/* Gutter 48px */
	&.gutter_48 {
		margin-left:-24px; margin-right:-24px;

		& > .column { padding-left:24px; padding-right:24px; }
	}

	/* Gutter 96px */
	&.gutter_96 {
		margin-left:-48px; margin-right:-48px;

		& > .column { padding-left:48px; padding-right:48px; }
	}
}
