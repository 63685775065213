/* ------------------------------------------------------------------------------------------------------------------------ */
/* LOGOS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

.logos_wrapper {
    position: relative;

    &.bleed_96 {
        padding-top:0;
        padding-left:0 !important;
        padding-right:0 !important;
    }

    @media screen and (max-width:768px) {
        padding-top:24px !important;
        padding-bottom:24px !important;

        &.bleed_96 {
            padding-left:96px !important;
            padding-right:96px !important;
        }
    }
}

// .logos {
//     list-style:none;
//     margin-left:-15px;
//     margin-right:-15px;

//     &,
//     .cycle-carousel-wrap {
//         display:flex;
//     }

//     .slide {
//         display: flex !important;
//         align-items: center;
//         justify-content: center;
//         opacity:1 !important;
//         padding-left:15px;
//         padding-right:15px;
//         box-sizing:border-box;
//         width:50%;

//         img {
//             max-width:100%;
//         }

//         @media screen and (min-width:640px) {
//             width:33.3333%;
//         }

//         @media screen and (min-width:768px) {
//             width:25%;
//         }

//         @media screen and (min-width:1024px) {
//             width:16.6666%;
//         }
//     }
// }

// .logos_controls {
//     a {
//         border-color:#202327 !important;
//         opacity:0.5;

//         &:hover,
//         &:focus {
//             opacity:0.75;
//         }
//     }

//     svg {
//         fill:#202327 !important;
//     }

//     .prev_type_1,
//     .next_type_1 {
//         position:absolute;
//         top:50%;
//         transform:translateY(-50%);
//     }

//     .prev_type_1 {
//         left:24px;

//         @media screen and (min-width:768px) {
//             left:auto;
//             right:calc(100% + 24px);
//         }
//     }

//     .next_type_1 {
//         right:24px;

//         @media screen and (min-width:768px) {
//             left:calc(100% + 24px);
//             right:auto;
//         }
//     }
// }

.logos {
    margin-left:-15px;
    margin-right:-15px;

    &::before,
    &::after {
        content:"";
        position:absolute;
        pointer-events:none;
        top:0;
        bottom:0;
        width:70px;
        z-index:1;

        @media screen and (min-width:768px) {
            width:120px;
        }
    }

    &::before {
        left:0;
        background-image:linear-gradient(to right, $color_theme_01-03, rgba($color_theme_01-03,0));

        .bg_theme_01-04 & {
            background-image:linear-gradient(to right, #e4e4e4, rgba(#e4e4e4,0));
        }
    }

    &::after {
        right:0;
        background-image:linear-gradient(to left, $color_theme_01-03, rgba($color_theme_01-03,0));

        .bg_theme_01-04 & {
            background-image:linear-gradient(to left, #e4e4e4, rgba(#e4e4e4,0));
        }
    }

    .slick-track {
        display:flex;
        align-items:center;
    }

    .slick-slide {
        margin-left:15px;
        margin-right:15px;
    }

    .slick-arrow {
        position:absolute;
        top:50%;
        transform:translateY(-50%);
        color:#202327;
        width:48px;
        height:48px;
        border:2px solid;
        border-radius:100%;
        background-color:transparent;
        opacity:0.5;
        transition:opacity 300ms;
        cursor:pointer;

        &:hover,
        &:focus {
            opacity:0.75;
        }

        svg {
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            width:14px;
            height:14px;
            fill:currentColor;
        }
    }

    .slick-prev {
        right:calc(100% + 12px);

        svg {
            transform:translate(-50%, -50%) rotate(0.5turn);
        }
    }

    .slick-next {
        left:calc(100% + 12px);
    }
}
