/*------------------------------------*\
    $HERO
\*------------------------------------*/

.illustrated-map {
    position: relative;
    overflow: hidden;
    // margin-top: -

    @media screen and (max-width: 799px) {
        display: none
    }
}

.illustrated-map-portable {
    margin-bottom: 30px;

    @media screen and (min-width: 800px) {
        display: none;
    }
}

.illustrated-map-item {
    position: absolute;
}

.illustrated-map-truck {
    width: 4.3%;
    left: -4.3%;
    top: 49.8%;
    animation: hero-truck linear 12s infinite;
}

.illustrated-map-tunnel-cover {
    width: 10.4%;
    left: 38.5%;
    top: 71.1%;
}

.illustrated-map-tunnel-cover-top {
    width: 9.6%;
    left: 6.9%;
    top: 57.9%;
}

@keyframes hero-truck {
    0% {
        left: -4.3%;
        top: 49.8%;
    }

    15%, 59.99999999% {
        left: 8%;
        top: 60.3%;
    }

    60% {
        left: 41.9%;
        top: 71.5%;
    }

    // 0% {
    //     left: 41.9%;
    //     top: 71.5%;
    // }

    100% {
        top: 100%;
        left: 76%;
    }
}

.illustrated-map-birds {
    width: 9%;
    top: 70%;
    left: -9%;
    animation: hero-birds linear 32s infinite;
    animation-delay: 2s;
}

@keyframes hero-birds {
    0% {
        left: -9%;
        top: 70%;
    }

    100% {
        top: -10%;
        left: 95%;
    }
}

// .illustrated-map-birds-inner {
.illustrated-map-birds {
    polygon, path {

        &:nth-of-type(even) {
            animation: hero-birds-inner 1s ease-in-out infinite;
        }
    }
}

@keyframes hero-birds-inner {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: translateY(5%);
    }
}

.illustrated-map-drone-left {
    left: 58.3%;
    top: 26%;
    animation: hero-drone-left 6s ease-in-out infinite;
}

@keyframes hero-drone-left {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: translateY(-50%);
    }
}


.illustrated-map-drone-right {
    left: 72.3%;
    top: 32.2%;
    animation: hero-drone-right 6s ease-in-out infinite;
}

@keyframes hero-drone-right {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: translateY(50%);
    }
}

.illustrated-map-turbine {
    left: 13.5%;
    top: 11.8%;
    width: 8.9%;
}

.illustrated-map-turbine-manned {
    left: 34.5%;
    top: 20.8%;
    width: 7.3%;
}

.illustrated-map-turbine-2 {
    left: 45.6%;
    top: 30%;
    width: 8.9%;
}

.illustrated-map-turbine-base {
    left: 49.4%;
    top: 47%;
    width: 3.3%;
}

.illustrated-map-turbine-3 {
    left: 78.6%;
    top: 31%;
    width: 8.9%;
}

.illustrated-map-turbine-4 {
    left: 84.6%;
    top: 24.8%;
    width: 8.9%;
}

.illustrated-map-crane-load {
    width: 2.5%;
    left: 55.6%;
    top: 18.9%;
}

.illustrated-map-crane-load-2 {
    width: 2.5%;
    left: 75.9%;
    top: 27.9%;
}

.illustrated-map-wide-door {
    width: 3.54%;
    left: 55.15%;
    top: 75.2%;
    animation: wide-door 7s ease-in-out infinite;
}

.illustrated-map-wide-door-cover {
    width: 3.74%;
    left: 55.05%;
    top: 73.6%;
}

@keyframes wide-door {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: translateY(-35%);
    }
}

.illustrated-map-door {
    width: 1.5%;
    left: 23.2%;
    top: 52.5%;
    animation: door 7s ease-in-out infinite;
}

.illustrated-map-door-cover {
    width: 1.5%;
    left: 23.2%;
    top: 51.1%;
}

.illustrated-map-door-2 {
    width: 1.5%;
    left: 68%;
    top: 65.2%;
    animation: door 7s ease-in-out infinite;
    animation-delay: 3.5s;
}

.illustrated-map-door-2-cover {
    width: 1.5%;
    left: 68%;
    top: 63.8%;
}

@keyframes door {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: translateY(-50%);
    }
}

.illustrated-map-person {
    width: 0.2%;
}

.illustrated-map-person-1 { left: 38.6%; top: 34.9%; }
.illustrated-map-person-2 { animation: person-2 10s linear infinite; }
.illustrated-map-person-3 { left: 49.5%; top: 19%; }
.illustrated-map-person-4 { left: 51.7%; top: 21.3%; }
.illustrated-map-person-5 { left: 56.2%; top: 14%; }
.illustrated-map-person-6 { animation: person-6 10s linear infinite; }
.illustrated-map-person-7 { left: 62.3%; top: 13.7%; }
.illustrated-map-person-8 { animation: person-8 40s linear infinite; }
.illustrated-map-person-9 { left: 61.8%; top: 30.3%; }
.illustrated-map-person-10 { left: 65.7%; top: 31.7%; }
.illustrated-map-person-11 { left: 66.3%; top: 29%; }
.illustrated-map-person-12 { left: 67.5%; top: 28.4%; }
.illustrated-map-person-13 { left: 67.5%; top: 29.6%; }
.illustrated-map-person-14 { left: 67.7%; top: 30.6%; }
.illustrated-map-person-15 { left: 69.1%; top: 34.7%; }

@keyframes person-2 {
    0%, 100% {
        left: 49.6%; top: 20.5%;
    }
    50% {
        left: 50%; top: 20%;
    }
}

@keyframes person-6 {
    0%, 100% {
        left: 56.5%; top: 14.9%;
    }
    50% {
        left: 56.8%; top: 14.6%;
    }
}

@keyframes person-8 {
    0%, 100% {
        left: 59.9%; top: 31.6%;
    }
    50% {
        left: 61.9%; top: 28.9%;
    }
}

.illustrated-map-person-climbing,
.illustrated-map-person-climbing-2,
.illustrated-map-person-climbing-3 {
    width: 0.4%;
}

.illustrated-map-person-climbing {
    left: 38.4%;
    top: 37.3%;
    animation: climb 28s linear infinite;
}

@keyframes climb {
    0%, 100% {
        left: 38.4%;
        top: 37.3%;
    }
    50% {
        left: 38.6%;
        top: 35.8%;
    }
}

.illustrated-map-person-climbing-2 {
    left: 59.4%;
    top: 34.8%;
    animation: climb-2 25s linear infinite;
}

@keyframes climb-2 {
    0%, 100% {
        left: 59.4%;
        top: 34.8%;
    }
    50% {
        left: 59.5%;
        top: 33.8%;
    }
}

.illustrated-map-person-climbing-3 {
    left: 60.7%;
    top: 35%;
    transform: scaleX(-1);
}

.illustrated-map-painting {
    width: 1%;
    height: 2%;
    left: 60.3%;
    top: 34.3%;
    background-color: #e95044;
    transform-origin: top left;
    animation: painting 15s linear infinite;
}

@keyframes painting {
    0% {
        transform: scaleX(0);
    }
    95% {
        transform: none;
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.illustrated-map-painting-cover {
    width: 1%;
    left: 60.3%;
    top: 34%;
}

.illustrated-map-smoke {
    width: 1%;
    opacity: 0;

    &::before {
        content: "";
        display: block;
        background-image: radial-gradient(rgba(#fff3da, .61) 0%, rgba(#fff3da, 0) 85%);
        padding-top: 100%;
        animation: sway 3s ease-in-out infinite;
    }
}

@keyframes sway {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: translateX(-50%);
    }
}

.illustrated-map-smoke-1,
.illustrated-map-smoke-3 {
    left: 46.3%;
    top: 15%;
    animation: smoke-1 5s linear infinite;
}

.illustrated-map-smoke-3 {
    animation-delay: 3s;
}

@keyframes smoke-1 {
    0%, 100% {
        transform: none;
        opacity: 0;
        left: 46.3%; top: 15%;
    }
    10% {
        opacity: 1;
        left: 46.3%; top: 15%;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
    90% {
        transform: scale(2);
        left: 46.3%;
        top: 10%;
    }
}

.illustrated-map-smoke-2,
.illustrated-map-smoke-4 {
    left: 46.3%;
    top: 15%;
    animation: smoke-2 5s linear infinite;
    animation-delay: 2s;
}

.illustrated-map-smoke-4 {
    animation-delay: 4s;
}

@keyframes smoke-2 {
    0%, 100% {
        transform: none;
        opacity: 0;
        left: 46.3%; top: 15%;
    }
    10% {
        opacity: 1;
        left: 46.3%; top: 15%;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
    90% {
        transform: scale(3);
        left: 50.3%;
        top: 10%;
    }
}

.illustrated-map-smoke-3,
.illustrated-map-smoke-5 {
    left: 47.3%;
    top: 16%;
    animation: smoke-3 5s linear infinite;
    animation-delay: 4s;
}

.illustrated-map-smoke-5 {
    animation-delay: 5s;
}

@keyframes smoke-3 {
    0%, 100% {
        transform: none;
        opacity: 0;
        left: 47.3%;
        top: 16%;
    }
    10% {
        opacity: 1;
        left: 47.3%;
        top: 16%;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
    90% {
        transform: scale(3);
        left: 50.3%;
        top: 8%;
    }
}


/**
 * Mobile Hero stuff
 */

.illustrated-map-figure {
    width: 100%;
}

.illustrated-map-section {
    padding: rem(38px) rem(25px) (rem(42px) - 30px);
    margin-bottom: 30px;
    background-color: rgba(#000, .2);
    border-radius: rem(3px);
    text-align: center;
}

.illustrated-map-figure-wrapper {
    position: relative;
    margin-bottom: 30px;
}

.illustrated-map-figure-electrical {
    .illustrated-map-door {
        width: 3.7%;
        left: 39.3%;
        top: 53.7%;
    }

    .illustrated-map-door-cover {
        width: 3.7%;
        left: 39.3%;
        top: 49%;
    }
}

.illustrated-map-figure-fabrication {
    .illustrated-map-wide-door {
        left: 49.3%;;
        width: 9.54%;
        top: 33.4%;
    }

    .illustrated-map-wide-door-cover {
        left: 49.3%;
        width: 9.54%;
        top: 28.3%;
    }
}

.illustrated-map-figure-turbine {
    .illustrated-map-figure {
        max-height: rem(250px);
    }

    .illustrated-map-turbine-base {
        top: 86%;
        width: rem(50px);
        left: 47.5%;
    }
}

.illustrated-map-figure-ship {
    .illustrated-map-crane-load {
        left: 59%;
        top: 47.5%;
        width: 12%;
    }

    .illustrated-map-smoke {
        width: 5%;
        // opacity: 1;
        // animation: none !important;?

        &::before {
            content: "";
            display: block;
            background-image: radial-gradient(rgba(#fff3da, .61) 0%, rgba(#fff3da, 0) 85%);
            padding-top: 100%;
            animation: sway 3s ease-in-out infinite;
        }
    }

    @keyframes sway {
        0%, 100% {
            transform: none;
        }

        50% {
            transform: translateX(-50%);
        }
    }

    .illustrated-map-smoke-1,
    .illustrated-map-smoke-3 {
        left: 17.3%;
        top: 30%;
        animation: smoke-1-mobile 5s linear infinite;
    }

    .illustrated-map-smoke-3 {
        animation-delay: 3s;
    }

    @keyframes smoke-1-mobile {
        0%, 100% {
            transform: none;
            opacity: 0;
            left: 17.3%;
            top: 30%;
        }
        10% {
            opacity: 1;
            left: 17.3%;
            top: 30%;
        }
        50% {
            opacity: 1;
        }
        75% {
            opacity: 0;
        }
        90% {
            transform: scale(2);
            left: 17.3%;
            top: 10%;
        }
    }

    .illustrated-map-smoke-2,
    .illustrated-map-smoke-4 {
        left: 23.3%;
        top: 33%;
        animation: smoke-2-mobile 5s linear infinite;
        animation-delay: 2s;
    }

    .illustrated-map-smoke-4 {
        animation-delay: 4s;
    }

    @keyframes smoke-2-mobile {
        0%, 100% {
            transform: none;
            opacity: 0;
            left: 23.3%;
            top: 33%;
        }
        10% {
            opacity: 1;
            left: 23.3%;
            top: 33%;
        }
        50% {
            opacity: 1;
        }
        75% {
            opacity: 0;
        }
        90% {
            transform: scale(3);
            left: 26.3%;
            top: 10%;
        }
    }

    .illustrated-map-smoke-3,
    .illustrated-map-smoke-5 {
        left: 17.3%;
        top: 30%;
        animation: smoke-3-mobile 5s linear infinite;
        animation-delay: 4s;
    }

    .illustrated-map-smoke-5 {
        animation-delay: 5s;
    }

    @keyframes smoke-3-mobile {
        0%, 100% {
            transform: none;
            opacity: 0;
            left: 17.3%;
            top: 30%;
        }
        10% {
            opacity: 1;
            left: 17.3%;
            top: 30%;
        }
        50% {
            opacity: 1;
        }
        75% {
            opacity: 0;
        }
        90% {
            transform: scale(3);
            left: 25.3%;
            top: 8%;
        }
    }
}

.illustrated-map-figure-rig {
    .illustrated-map-drone-left {
        left: 1.3%;
        top: 32.2%;
        width: 20%;
    }

    .illustrated-map-drone-right {
        left: 72.3%;
        top: 62.2%;
        width: 20%;
    }

    .illustrated-map-crane-load {
        width: 12%;
        left: 84.6%;
        top: 40.6%;
    }
}
