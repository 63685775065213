/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVE IMAGES ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.responsiveImg {

	img {
		position:absolute;
		left:-9999px;
		max-width:none;
		opacity:0;
	}
}
