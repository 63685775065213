/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.buttons-wrapper {

	@media screen and (min-width:769px)
	{
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;

		.button:not(:last-child) {
			margin-right:12px;
		}
	}

	@media screen and (max-width:768px)
	{
		padding-top:48px;

		.button:not(:first-child) {
			margin-top:12px;
		}
	}
}

.button {
	transition:color 300ms ease-in-out, background 300ms ease-in-out, border 300ms ease-in-out;
	display:inline-block;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 1 & 2) ---------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.button_type_1,
.button_type_2 {
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	align-items:center;
	color:$color_theme_01-03;

	svg {
		@extend .animate;
		padding-left:16px;
		width:18px; height:18px;
		fill:#FFF;
	}

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {

			svg {
				transform:translate(6px);
			}
		}
	}
}
.button_type_1 {
	padding:24px 32px;
	font-size:18px;
	font-family:'gilroy-medium', sans-serif;
	background:$color_theme_01-02;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			background:$color_theme_01-04;
		}
	}
	@media screen and (max-width:768px)
	{
		padding:18px 24px;
	}
}
.button_type_2 {
	padding:24px 38px;
	font-size:22px;
	font-family:'gilroy-semibold', sans-serif;
	background:rgba($color_theme_01-03, .06);

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			background:$color_theme_01-02;
		}
	}
	@media screen and (max-width:768px)
	{
		justify-content:space-between;
		padding:18px 24px;
		width:100%;
		font-size:18px;
		box-sizing:border-box;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 3) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.button_type_3 {
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	align-items:center;
	justify-content:center;
	padding:25px 32px;
	color:$color_theme_01-01;
	font-size:18px;
	font-family:'gilroy-extrabold', sans-serif;
	text-transform:uppercase;
	background-color:$color_theme_01-03;

	@media screen and (max-width:768px)
	{
		padding:16px 24px;
		font-size:$font_size_base;
	}

	span:first-child {
		@extend .animate;
		transform:translateX(16px);

		@media screen and (max-width:768px)
		{
			margin:0 auto;
			transform:none;
		}
	}
	.icon {
		@extend .animate;
		overflow:hidden;
		width:32px; height:18px;
		opacity:0;
		transform:translateX(-16px);

		@media screen and (max-width:768px)
		{
			display:none;
		}

		svg {
			display:block;
			padding-left:16px;
			width:16px; height:18px;
			fill:$color_theme_01-01;
		}
	}

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {

			span:first-child {
				transform:translateX(0);
			}
			.icon {
				opacity:1;
				transform:translateX(0);
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (TYPE 4) -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.button_type_4 {
	display:flex;
	flex-direction:row;
	flex-wrap:nowrap;
	align-items:center;
	padding:24px 38px;
	color:$color_theme_01-02 !important;
	font-size:18px;
	font-family:'gilroy-semibold', sans-serif;
	background:none !important;
	border:2px solid $color_theme_01-02;
	box-sizing:border-box;

	svg {
		@extend .animate;
		padding-left:16px;
		width:18px; height:18px;
		fill:$color_theme_01-02;
	}

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:#FFF !important;
			border-color:#FFF;

			svg {
				fill:#FFF;
				transform:translate(6px);
			}
		}
	}
	@media screen and (max-width:768px)
	{
		justify-content:space-between;
		padding:18px 24px;
		width:100%;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (SCROLL DOWN) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.scroll_down {
	display:block;
	margin:-12px; padding:12px;
	width:28px; height:28px;

	svg {
		@extend .animate;
		display:block;
		width:28px; height:28px;

		circle {
			transition:transform 300ms ease-in-out;
		}
	}

	@media screen and (min-width:769px)
	{
		&:hover svg circle,
		&:focus svg circle {
			transform:translateY(14px);
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (SCROLL UP) ----------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.scroll_up {
	display:block;
	margin:-12px; padding:12px;
	width:28px; height:28px;

	svg {
		@extend .animate;
		display:block;
		width:28px; height:28px;
		transform:rotate(-90deg);
	}

	@media screen and (min-width:769px)
	{
		&:hover svg,
		&:focus svg {
			transform:rotate(-90deg) translateX(6px);
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (PREV / NEXT) --------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.prev_type_1,
.next_type_1 {
	@extend .animate;
	display:flex;
	flex-direction:column;
	flex-wrap:nowrap;
	align-items:center;
	justify-content:center;
	width:48px; height:48px;
	border:2px solid rgba($color_theme_01-03, .12);
	border-radius:100%;
	box-sizing:border-box;

	svg  {
		@extend .animate;
		display:block;
		width:14px; height:14px;
		fill:rgba($color_theme_01-03, .12);
	}

	@media screen and (min-width:769px)
	{
		&:not(.disabled):hover,
		&:not(.disabled):focus {
			border:2px solid $color_theme_01-03;

			svg {
				fill:$color_theme_01-03;
			}
		}
	}

	&.disabled {
		cursor:default;
	}
}
.prev_type_1 svg {
	transform:rotate(180deg);
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BUTTON (CLOSE) --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.close_type_1 {
	@extend .animate;
	position:fixed;
	z-index:100;
	background:$color_theme_01-01;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		top:34px; right:48px;
	}
	@media screen and (max-width:768px)
	{
		top:0; right:0;
		width:48px; height:48px;
	}

	svg {
		@extend .animate;
		display:block;
		padding:25px;
		width:14px; height:14px;
		fill:rgba($color_theme_01-03, .32);

		@media screen and (max-width:768px)
		{
			padding:17px;
			fill:$color_theme_01-03;
		}
	}

	@media screen and (min-width:769px)
	{
		top:24px; right:24px;
		width:64px; height:64px;
		border-radius:100%;

		&:hover svg,
		&:focus svg {
			fill:$color_theme_01-03;
		}
	}
}
