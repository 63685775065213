#masks {
	position:absolute;
	top:-999em; left:-999em;
	z-index:1;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* HERO ------------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero {
	@extend .relative;
	width:100%; height:100vh;
	color:$color_theme_01-03;
	background:$color_theme_01-01;
	overflow:hidden;

	@media screen and (max-width:768px)
	{
		z-index:102;
	}

	&.short {
		height:calc(75vh - 228px); min-height:400px;

		@media screen and (max-width:768px)
		{
			padding-top:72px;
			height:auto; min-height:0;
		}

		.heading {
			top:calc(228px - 96px); bottom:0;
			height:auto;

			@media screen and (max-width:768px)
			{
				position:static;
				padding:0 24px 32px;
			}

			h1 {

				@media screen and (min-width:769px)
				{
					text-align:center;
				}
			}
		}
	}

	.cycle {
		@extend .full_size;
		overflow:hidden;

		.slide {
			width:100%; height:100%;

			/*
			&.cycle-slide-active {

				.heading h1 strong {
					opacity:1;

					&:before {
						left:0;
					}
					&:after {
						right:0;
					}
				}
			}
			*/
		}

		&.cycle_hero_heading {
			z-index:3;
		}
	}

	/*
	.hero_bg_wrapper {
		@extend .full_size;
		overflow:hidden;

		.background {
			@extend .absolute;
			width:110%; height:100%;
			transform:translateX(-5%);

			img {
				display:block;
				width:100%; height:100%;
				object-fit:cover; font-family:'object-fit:cover;';
			}

			&.slice_01 {
				clip-path:url('#slice-01');
			}
			&.slice_02 {
				clip-path:url('#slice-02');
			}
			&.slice_03 {
				clip-path:url('#slice-03');
			}
			&.slice_04 {
				clip-path:url('#slice-04');
			}

			@media screen and (max-width:768px)
			{
				img {
					object-position:center center !important;
				}

				&.slice_02,
				&.slice_04 {
					opacity:.76 !important;
				}
				&.slice_03 {
					opacity:.88 !important;
				}
			}
		}
	}
	*/

	/*
	.hero_bg_wrapper {
		@extend .full_size;
		overflow:hidden;

		svg {

			.svgImage01 { clip-path:url(#svgPath01); }
			.svgImage02 { clip-path:url(#svgPath02); }
			.svgImage03 { clip-path:url(#svgPath03); }
			.svgImage04 { clip-path:url(#svgPath04); }

			image {
				object-fit:cover; font-family:'object-fit:cover;';
			}
		}
	}
	*/

	.hero_bg {
		position:absolute;
		top:50%; left:50%;
		z-index:3;
		width:calc(100% + 80px); height:auto;
		overflow:hidden;
		transform:translate(-50%, -50%);

		&.portrait {
			width:auto; height:calc(100% + 80px);
		}

		&.hidden {
			opacity:0;

			& + picture {
				display:block;
			}
		}

		& + picture {
			@extend .full_size;
			display:none;

			img {
				display:block;
				width:100%; height:100%;
				object-fit:cover; font-family:'object-fit:cover;';

				@media screen and (max-width:768px)
				{
					margin-top:-48px;
				}
			}
		}
	}

	.heading {
		position:absolute;
		top:0; left:0;
		z-index:4;
		padding:0 96px;
		width:100%; height:100%;
		box-sizing:border-box;

		@media screen and (max-width:1024px)
		{
			padding:0 48px;
		}

		.center_content {
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			align-items:center;
			justify-content:center;
			width:100%; height:100%;
		}

		h1 {
			width:100%;
			font-size:24px;
			text-align:center;

			@media screen and (max-width:768px)
			{
				display:flex;
				flex-direction:column-reverse;
			}

			strong {
				@extend .relative;
				font-weight:400;
				// opacity:0;

				/*
				&:before {
					@extend .animate;
					content:attr(data-letters);
					position:absolute;
					top:0; left:-12px;
					z-index:2;
				}
				&:after {
					@extend .animate;
					content:attr(data-letters);
					position:absolute;
					top:0; right:-12px;
					z-index:2;
				}
				*/
			}
			span {
				@extend .heading_type_0;

				@media screen and (max-width:768px)
				{
					padding-bottom:12px;
				}
			}

			&.small_gap {
				span {
					line-height:0.8;
				}
			}
		}

		a {
			margin-top:48px;
		}
	}

	.bottom {
		position:absolute;
		bottom:0; left:0; right:0;
		z-index:4;
		padding:96px;

		@media screen and (max-width:1024px) and (min-width:769px)
		{
			padding:48px;
		}
		@media screen and (max-width:768px)
		{
			position:fixed;
			padding:0;

			.button.button_type_1 {
				width:100%;
			}
		}

		.center_content {
			display:flex;
			flex-direction:row;
			flex-wrap:nowrap;
			align-items:center;
			justify-content:space-between;
		}

		.scroll_down {

			svg {
				fill:$color_theme_01-03;
			}

			@media screen and (min-width:769px)
			{
				&:hover svg,
				&:focus svg {
					fill:$color_theme_01-02;
				}
			}
			@media screen and (max-width:768px)
			{
				display:none;
			}
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* IN-SECTION HERO -------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.hero_in {
	@extend .relative;
	width:100%; max-height:$site_width_small;
	color:$color_theme_01-03;
	background:$color_theme_01-01;
	overflow:hidden;

	&:before {
		content:"";
		@extend .relative;
		display:block;
		padding-top:37.50%;
		width:100%; height:0;
		overflow:hidden;

		@media screen and (max-width:768px)
		{
			padding-top:56.25%;
		}
	}

	.heading {
		position:absolute;
		top:0; left:0;
		z-index:3;
		width:100%; height:100%;

		.center_content {
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			align-items:center;
			justify-content:center;
			padding:0 96px;
			width:100%; height:100%;
			box-sizing:border-box;

			@media screen and (max-width:768px)
			{
				padding:0 48px;
			}

			h2 {
				@extend .heading_typo_1;
				text-align:center;

				@media screen and (max-width:768px)
				{
					font-size:24px;
				}
			}
		}
	}

	.bg_wrapper {
		@extend .full_size;
		overflow:hidden;

		/*
		.background {
			@extend .absolute;
			width:110%; height:100%;
			transform:translateX(-5%);

			img {
				display:block;
				width:100%; height:100%;
				object-fit:cover; font-family:'object-fit:cover;';
			}

			&.slice_05 {
				clip-path:url('#slice-05');
			}
			&.slice_06 {
				clip-path:url('#slice-06');
			}
			&.slice_07 {
				clip-path:url('#slice-07');
			}

			@media screen and (max-width:768px)
			{
				img {
					object-position:center center !important;
				}

				&.slice_05,
				&.slice_06 {
					opacity:.88 !important;
				}
			}
		}
		*/

		.hero_in_bg {
			position:absolute;
			top:50%; left:50%;
			z-index:3;
			width:calc(100% + 80px); height:auto;
			overflow:hidden;
			transform:translate(-50%, -50%);

			&.portrait {
				width:auto; height:calc(100% + 80px);
			}

			&.hidden {
				opacity:0;

				& + .lazyload_wrapper {
					display:block;
				}
			}

			& + .lazyload_wrapper  {
				@extend .full_size;
				display:none;

				img {
					display:block;
					width:100%; height:100%;
					object-fit:cover; font-family:'object-fit:cover;';
				}
			}
		}
	}
}
