/* ------------------------------------------------------------------------------------------------------------------------ */
/* BODY ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
body.single-post {
	overflow:hidden !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WRAPPER ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wrapper {
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* CENTER CONTENT --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.center_content {
	margin:0 auto;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MAIN CONTAINER --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.main_container {
	flex:1;
}

.testimonials_wrapper,
.latest_news_wrapper {

	.controls {
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		align-items:center;

		@media screen and (max-width:768px)
		{
			justify-content:space-between;
		}

		.caption {
			padding:0 32px;
			color:$color_theme_01-03;
		}

		&.hide {
			display:none;
		}
	}
}
.testimonials_wrapper {
	@extend .relative;

	.controls {
		background:$color_theme_01-01;

		@media screen and (min-width:769px)
		{
			position:absolute;
			right:-24px; bottom:-37px;
			z-index:101;
			padding:24px;
		}
		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}
	}
}
.latest_news_wrapper {

	.controls {
		justify-content:center;
		padding-top:48px;

		@media screen and (max-width:768px)
		{
			padding-top:24px;
		}
	}
}

.services_wrapper {

	ul > li {
		padding-left:35px;
		color:rgba($color_theme_01-01, .48);

		&:before {
			top:calc(.5em + 11px); left:0;
			width:11px; height:11px;
			background:none;
			border:2px solid $color_theme_01-02;
			box-sizing:border-box;
		}
	}
}

.careers_wrapper {
	@extend .relative;

	.center_content {
		position:relative;
		z-index:3;
	}

	.background {
		@extend .full_size;
		overflow:hidden;

		img {
			display:block;
			width:100%; height:100%;
			object-fit:cover; font-family:'object-fit:cover;';
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* WIDTHS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.wBase {
	max-width:$site_width_base;
}
.wMedium {
	max-width:$site_width_medium;
}
.wSmall {
	max-width:$site_width_small;
}
.wXSmall {
	max-width:$site_width_xsmall;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BLEEDS ----------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.bleed_48 {
	padding:48px;
	width:calc(100% - 96px);
}
.bleed_96 {
	padding:96px;
	width:calc(100% - 192px);

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px;
		width:calc(100% - 96px);
	}
	@media screen and (max-height:768px) and (min-width:769px) and (orientation:landscape)
	{
		padding:48px 96px;

		.box_type_1 & {
			padding-left:0;
			padding-right:0;
		}
	}
}
.bleed_48,
.bleed_96 {

	@media screen and (max-width:768px)
	{
		padding:24px;
		width:auto;
	}
}

.bleed_top_12 {
	padding-top:12px;
}
.bleed_top_24 {
	padding-top:24px;
}
.bleed_top_48 {
	padding-top:48px;
}
.bleed_top_96 {
	padding-top:96px;
}
.bleed_top_12 {

	@media screen and (max-width:768px)
	{
		padding-top:6px;
	}
}
.bleed_top_24 {

	@media screen and (max-width:768px)
	{
		padding-top:12px;
	}
}
.bleed_top_48,
.bleed_top_96 {

	@media screen and (max-width:768px)
	{
		padding-top:24px;
	}
}

.bleed_bottom_48 {
	padding-bottom:48px;
}
.bleed_bottom_48 {

	@media screen and (max-width:768px)
	{
		padding-bottom:24px;
	}
}

.bleed_bottom_24 {
	padding-bottom:24px;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BACKGROUNDS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.bg_theme_01-01 {
	background:$color_theme_01-01;
}
.bg_theme_01-02 {
	background:$color_theme_01-02;
}
.bg_theme_01-03 {
	background:$color_theme_01-03;
}
.bg_theme_01-04 {
	background:#e4e4e4;
}