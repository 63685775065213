/* ------------------------------------------------------------------------------------------------------------------------ */
/* MODAL WINDOW ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-modal_wrapper {
	display:none;
	position:fixed;
	top:0px; left:0px; right:0px; bottom:0px;
	z-index:99999;
	background:rgba(#000, .88);

	@media screen and (max-width:768px)
	{
		&:not(.modal_flash_message) {
			background:#FFF;
			overflow:auto;

			.js-modal_content {
				position:static;
				top:0; left:0;
				padding:0;
				width:auto; max-width:none; min-height:0;
				background:none;
				overflow:auto;
				transform:none;
			}
		}

		&.modal_flash_message .js-modal_content {
			padding:24px;

			&.wXSmall {
				max-width:calc(#{$site_width_xsmall} + 48px);
			}
		}
	}

	.js-modal_content {
		position:absolute;
		top:50%; left:50%;
		z-index:2;
		padding:0 72px;
		width:100%;
		box-sizing:border-box;
		transform:translate(-50%, -50%);

		&.wXSmall {
			max-width:calc(#{$site_width_xsmall} + 144px);
		}
	}

	.js-modal_trigger {
		display:inline-block;
		padding:18px 24px;
		min-width:100px;

		@media screen and (min-width:769px)
		{
			&:hover,
			&:focus {
				color:$color_theme_01-03;
				background:darken($color_theme_01-02, 18%);
			}
		}
	}

	&.fixedHeight {
		overflow:auto;

		.js-modal_content {
			position:relative;
			top:auto; left:auto;
			margin:24px auto;
			transform:none;

			@media screen and (max-width:768px)
			{
				margin:0;
			}
		}
	}

	&.start_open {
		display:block;
	}

	&.modal_error .js-modal_content {
		border-top:4px solid $color_error;
	}
	&.modal_alert .js-modal_content {
		border-top:4px solid $color_alert;
	}
	&.modal_valid .js-modal_content {
		border-top:4px solid $color_valid;
	}
	&.modal_update .js-modal_content {
		border-top:4px solid $color_update;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* NO-JS ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */
.js-modal_trigger_no-js {
	display:none !important;
	opacity:0 !important;
	visibility:hidden !important;
}
.no-js {

	.js-modal_wrapper:target {
		display:block;
	}

	.js-modal_trigger_no-js {
		display:block !important;
		opacity:1 !important;
		visibility:visible !important;

		& + a {
			display:none !important;
			opacity:0 !important;
			visibility:hidden !important;
		}
	}
}
