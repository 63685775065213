/* ------------------------------------------------------------------------------------------------------------------------ */
/* BOX (TYPE 1) ----------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.box_type_1 {
	padding:96px 0;

	@media screen and (max-width:1024px) and (min-width:769px)
	{
		padding:48px 0;
	}
	@media screen and (max-width:768px)
	{
		padding:8px 0;
	}
	@media screen and (max-height:768px) and (min-width:769px) and (orientation:landscape)
	{
		padding:48px 96px;
	}
}
