/* ------------------------------------------------------------------------------------------------------------------------ */
/* ARTICLE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.ajax_article {
	@extend .animate;
	position:fixed;
	z-index:1;
	visibility:hidden;
	opacity:0;

	&.open {
		top:0; left:0; right:0; bottom:0;
		z-index:9999;
		visibility:visible;
		opacity:1;

		@media screen and (max-width:768px)
		{
			background:$color_theme_01-01;
			overflow-y:auto;
		}
	}
}

.article_wrapper {
	@extend .relative;
	z-index:9999;

	@media screen and (max-width:768px)
	{
		display:flex;
		flex-direction:column;
		flex-wrap:nowrap;
	}

	&:before {
		content:"";

		@media screen and (min-width:769px)
		{
			position:fixed;
			top:0; left:0; bottom:0;
			z-index:2;
			width:50%;
			background:$color_theme_01-01;
		}
	}

	.background {
		background:$color_theme_01-01;

		@media screen and (min-width:769px)
		{
			position:fixed;
			top:0; left:0; right:0; bottom:0;
			z-index:1;
		}
		@media screen and (max-width:768px)
		{
			order:1;
		}

		img {
			display:block;
			width:100%; height:100%;
			object-fit:cover; font-family:'object-fit:cover;';
		}
	}

	.article_meta,
	.article_content {

		@media screen and (min-width:769px)
		{
			position:fixed;
			top:0; bottom:0;
			z-index:3;
			width:50%;
			overflow:hidden; overflow-y:auto;
		}
		@media screen and (max-width:768px)
		{
		}

		.column {
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			flex:1;
			padding:88px 96px;
			box-sizing:border-box;

			@media screen and (max-width:1024px) and (min-width:769px)
			{
				padding:48px;
			}
			@media screen and (max-width:768px)
			{
				padding:32px 24px;
			}
		}
	}
	.article_meta {

		@media screen and (min-width:769px)
		{
			display:flex;
			flex-direction:column;
			flex-wrap:nowrap;
			align-items:flex-end;
			left:0;
		}
		@media screen and (max-width:768px)
		{
			order:2;
		}

		.column {
			display:block;
			width:100%; max-width:($site_width_base / 2) + 96px;
			color:$color_theme_01-03;
			flex:none;
			min-height:100%;

			.height {
				position: relative;
				height:100%;

				@media screen and (min-width:769px)
				{
					box-sizing:border-box;
					padding-bottom:152px;

					.share_navigation {
						position:absolute;
						bottom:0;
						left:0;
						right:0;
					}
				}
			}
		}
	}
	.article_content {
		right:0;

		@media screen and (max-width:768px)
		{
			order:3;
		}

		.column {
			background:$color_theme_01-03;

			@media screen and (min-width:769px)
			{
				min-height: 100vh;
				width:calc(100%);
			}
			@media screen and (max-width:1024px) and (min-width:769px)
			{
				width:calc(100% - 48px);
			}
		}

		.share_navigation {
			display:none;

			@media screen and (max-width:768px)
			{
				display:flex;
			}
		}
	}

	.logo {

		@media screen and (max-width:768px)
		{
			display:none;
		}
	}

	.date,
	.category {
		line-height:1.4;
		color:rgba($color_theme_01-03, .48);
		font-size:$font_size_small;
		font-family:'gilroy-medium', sans-serif;
		text-transform:uppercase;

		a,
		span {
			@extend .animate;
			@extend .relative;
			color:rgba($color_theme_01-03, .48);
			font-size:$font_size_base;
			text-transform:none;
		}
		a:after {
			@extend .animate;
			@extend .absolute;
			content:"";
			left:0; right:0; bottom:0;
			height:1px;
			background:rgba($color_theme_01-03, .48);
		}

		@media screen and (min-width:769px)
		{
			a:hover,
			a:focus {
				color:$color_theme_01-03;

				&:after {
					background:$color_theme_01-03;
				}
			}
		}
	}
	.date {

		@media screen and (min-width:769px)
		{
			padding-top:96px;
		}
		@media screen and (max-width:1024px) and (min-width:769px)
		{
			padding-top:48px;
		}
	}
	.category {
		padding-top:24px;

		@media screen and (max-width:768px)
		{
			padding-top:18px;
		}
	}

	.title {
		padding-top:12px;
		@include fluid-type(360px, 1600px, 24px, 64px);
	}
}
