/* ------------------------------------------------------------------------------------------------------------------------ */
/* CLEARFIX --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.clearfix {
	*zoom:1;

	&:before,
	&:after {
		content:"";
		display:table;
		line-height:0;
	}
	&:after {
		clear:both;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ALIGNMENT -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.left {
	float:left;
}
.right {
	float:right;
}
.center {
	margin:0 auto;
}

.text-left {
	text-align:left !important;
}
.text-right {
	text-align:right !important;
}
.text-center {
	text-align:center !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* MARGINS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_margin {
	margin:0 !important;
}
.reset_margin_top {
	margin-top:0 !important;
}
.reset_margin_right {
	margin-right:0 !important;
}
.reset_margin_bottom {
	margin-bottom:0 !important;
}
.reset_margin_left {
	margin-left:0 !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* PADDINGS --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_padding {
	padding:0 !important;
}
.reset_padding_top {
	padding-top:0 !important;
}
.reset_padding_right {
	padding-right:0 !important;
}
.reset_padding_bottom {
	padding-bottom:0 !important;
}
.reset_padding_left {
	padding-left:0 !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BORDERS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.reset_border {
	border:0 solid !important;
}
.reset_border_top {
	border-top:0 solid !important;
}
.reset_border_right {
	border-right:0 solid !important;
}
.reset_border_bottom {
	border-bottom:0 solid !important;
}
.reset_border_left {
	border-left:0 solid !important;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* POSITIONS -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.relative {
	position:relative;
	z-index:1;
}
.absolute {
	position:absolute;
	z-index:1;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* COLOURS ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Dark gray --- */
.color_01-01,
a.color_01-01, {
	color:$color_theme_01-01 !important;
}
a.color_01-01, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:$color_theme_01-01 !important;
			cursor:pointer;
		}
	}
}

/* --- White --- */
.color_white,
a.color_white, {
	color:#FFF !important;
}
a.color_white, {
	@extend .animate;

	@media screen and (min-width:769px)
	{
		&:hover,
		&:focus {
			color:#FFF !important;
			cursor:pointer;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* BACKGROUNDS ------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL SIZE -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_size {
	position:absolute;
	top:0; left:0; right:0; bottom:0;
	z-index:2;
	width:100%; height:100%;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* FULL WIDTH ------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.full_width {
	display:block;
	width:100%; height:auto;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* ANIMATE ---------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.animate {
	transition-duration:300ms;
	transition-timing-function:ease-in-out;
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* RESPONSIVENESS --------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */
.only_desktop {
	display:none;

	@media screen and (min-width:769px)
	{
		display:block;
	}
}

.only_mobile {
	display:none;

	@media screen and (max-width:768px)
	{
		display:block;
	}
}

/* ------------------------------------------------------------------------------------------------------------------------ */
/* KEYFRAMES -------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

/* --- Spin --- */
@-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
